import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import Header from "../../layout/Header/Header"
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { setIndex } from "@amcharts/amcharts4/.internal/core/utils/Array";

function Configuraciones() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CONFIGURACIONES = process.env.REACT_APP_URL_CONFIGURACIONES;
  const [config, setConfig] = useState([]);
  const [email_aut_gastos, setEmailAutGastos] = useState("");
  const [email_aut_presupuestos, setEmailAutPresupuestos] = useState("");
  const [email_aut_nominas, setEmailAutNominas] = useState("");
  const [email_abono_facturas, setEmailAbonoFacturas] = useState("");
  
  const [email_aut_gastos2, setEmailAutGastos2] = useState("");
  const [email_aut_presupuestos2, setEmailAutPresupuestos2] = useState("");
  const [email_aut_nominas2, setEmailAutNominas2] = useState("");
  const [email_abono_facturas2, setEmailAbonoFacturas2] = useState("");
  
  const [id, setId] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_CONFIGURACIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allConfig = response.data;
        setConfig(allConfig);
        console.log(allConfig)
        setEmailAutPresupuestos(response.data[0].email_aut_presupuestos[0])
        setEmailAutGastos(response.data[0].email_aut_gastos[0])
        setEmailAutNominas(response.data[0].email_aut_nominas[0])
        setEmailAbonoFacturas(response.data[0].email_abono_facturas[0])

        setEmailAutPresupuestos2(response.data[0].email_aut_presupuestos[1])
        setEmailAutGastos2(response.data[0].email_aut_gastos[1])
        setEmailAutNominas2(response.data[0].email_aut_nominas[1])
        setEmailAbonoFacturas2(response.data[0].email_abono_facturas[1])
        setId(response.data[0]._id)
      })
      .catch((err) => {
        console.log(err);
      });
    }, []);


  const saveConfiguraciones = (event) => {
    event.preventDefault();
    setValidaBoton(false)
    let presupuestos = new Array(email_aut_presupuestos, email_aut_presupuestos2)  .filter(function (el) {
      return el != "" && el !=null;
    });
    let gastos = new Array(email_aut_gastos, email_aut_gastos2)  .filter(function (el) {
      return el != "" && el !=null;
    });
    let nominas = new Array(email_aut_nominas, email_aut_nominas2)  .filter(function (el) {
      return el != "" && el !=null;
    });
    let facturas = new Array(email_abono_facturas, email_abono_facturas2)  .filter(function (el) {
      return el != "" && el !=null;
    });
    axios
      .patch(
        `${URL_CONFIGURACIONES}/${id}`,
        {
          email_abono_facturas:facturas,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Actualiza Configuracion',
            detalle: `${email_aut_gastos} / ${email_aut_presupuestos} / ${email_aut_nominas} / ${email_abono_facturas}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);

      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  return (
    <>
     <Header />
      <br />
      <br />
      <div className="card container">
        <h3 align="center">Configuracion General</h3>
        <Form onSubmit={saveConfiguraciones}>
          
            {/* <Label>
              Email Autorizacion Presupuestos
            </Label>
        <Row>
            <Col md={4}>
            <Input
              type="text"
              name="email"
              placeholder={email_aut_presupuestos}
              value={email_aut_presupuestos}
              onChange={(e) => {
                setEmailAutPresupuestos(e.target.value);
              }}
            />
            </Col>
            <Col md={4}>
            <Input
              type="text"
              name="email"
              placeholder={email_aut_presupuestos2}
              value={email_aut_presupuestos2}
              onChange={(e) => {
                setEmailAutPresupuestos2(e.target.value);
              }}
            />
            </Col>
            </Row>
<br />

             <Label>
              Email Autorizacion Gastos
            </Label>
            <Row>
            <Col md={4}>
            <Input
              type="text"
              name="email"
              placeholder={email_aut_gastos}
              value={email_aut_gastos}
              onChange={(e) => {
                setEmailAutGastos(e.target.value);
              }}
            /> 
            </Col>
            <Col md={4}>
            <Input
              type="text"
              name="email"
              placeholder={email_aut_gastos2}
              value={email_aut_gastos2}
              onChange={(e) => {
                setEmailAutGastos2(e.target.value);
              }}
            /> 
            </Col>
            </Row>

            <br />

             <Label>
              Email Autorizacion Nominas
            </Label>
            <Row>
            <Col md={4}>
            <Input
              type="text"
              name="email"
              placeholder={email_aut_nominas}
              value={email_aut_nominas}
              onChange={(e) => {
                setEmailAutNominas(e.target.value);
              }}
            />  
            </Col>
            <Col md={4}>
            <Input
              type="text"
              name="email"
              placeholder={email_aut_nominas2}
              value={email_aut_nominas2}
              onChange={(e) => {
                setEmailAutNominas2(e.target.value);
              }}
            /> 
            </Col>
            </Row>


            <br />

            */}



            <br />
             <Label>
              Email Abonos
            </Label>
            <Row>
            <Col md={4}>
            <Input
              type="text"
              name="email"
              placeholder={email_abono_facturas}
              value={email_abono_facturas}
              onChange={(e) => {
                setEmailAbonoFacturas(e.target.value);
              }}
            /> 
            </Col>
            <Col md={4}>
            <Input
              type="text"
              name="email"
              placeholder={email_abono_facturas2}
              value={email_abono_facturas2}
              onChange={(e) => {
                setEmailAbonoFacturas2(e.target.value);
              }}
            />                   
            </Col>
            </Row>

          <br />
          <Row>
          {validaBoton ? (
        <Button type="submit" className="btn btn-success">
        Guardar
      </Button>
      ):(
      <Button type="submit" className="btn btn-success" disabled>
        Guardar
      </Button>)}
          </Row>
        </Form>
      </div>
    </>
  );
}

export default Configuraciones;

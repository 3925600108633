import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import TablaDepartamentos from "./TablaDepartamentos";
import DepartamentosCreate from "./DepartamentosCreate";
import axios from "axios";
import { Table } from "reactstrap";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";

function ListadoDepartamentos() {
  const { user } = useContext(AuthContext);
  const URL_DEPARTAMENTOS = process.env.REACT_APP_URL_DEPARTAMENTOS;
  const [departamentos, setDepartamentos] = useState([]);

  useEffect(() => {
    axios
      .get(URL_DEPARTAMENTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDepartamentos = response.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_departamentos  ?(
      <div className="container">
        <div className="row">
          {
            <div className="col-md-6 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <h3 align="center">Departamentos</h3>
                  <Table size="sm" striped borderless className="table-responsive-xl">
                    <thead>
                      <tr>
                        <th className="tituloTabla">Nombre</th>
                        <th className="tituloTabla">Editar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {departamentos
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return (
                            <tr>
                              <TablaDepartamentos name={a.name} _id={a._id} is_active={a.is_active} />
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          }
          <div className="col-md-6 col-sm-6">
            <div className="card">
              <div className="card-body">
                <DepartamentosCreate />
              </div>
            </div>
          </div>
        </div>
      </div>
      ): undefined } 
      
    </>
  );
}

export default ListadoDepartamentos;

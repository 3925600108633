import React from "react";
import { Spinner } from "reactstrap";

const FullPageLoader = () => {
    return (
        <div className="fp-container">
            <Spinner style={{ width: '3rem', height: '3rem', color:"#232B41" }} type="grow"  />
        </div>
    );
};

export default FullPageLoader;
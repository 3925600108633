import React, { createContext, useState, useEffect } from 'react';
import decode from 'jwt-decode'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";


export const AuthContext = createContext();

const AuthContextProvider = (props) => {  
  const [token, setToken] = useState('')
  const [isAuth, setIsAuth] = useState(false)
  const [user, setUser] = useState({})

  const loginUser = (token)=>{
    localStorage.setItem('app_token', token)
    const decoded = decode(token)
    setToken(token)
    setIsAuth(true)
    setUser(decoded)
  }

  const logoutUser = (user)=>{
    localStorage.removeItem('app_token')
    setToken({})
    setIsAuth(false)
    setUser(null)
  }

  const salir =()=>{
    const token = localStorage.getItem('app_token')
    let dateNow = Math.floor(Date.now()/1000)
    const decoded = decode(token)
    let timexp = decoded.exp
    if (dateNow >= timexp){
      localStorage.removeItem('app_token')
      setToken({})
      setIsAuth(false)
      setUser(null)
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Tu sesion ha caducado",
        showConfirmButton: false,
        timer: 2000,
      });
      setTimeout(() => {
        window.location.href = '/login'
      }, 1000);
    }
  }
  

  useEffect(()=>{
    const item = localStorage.getItem('app_token')
    if (item){
      const decoded = decode(item)
      setToken(item)
      setIsAuth(true)
      setUser(decoded)
      salir()
    }
  }, [])

  return (
        <AuthContext.Provider
      value={{
        token,
        isAuth,
        user,
        loginUser,
        logoutUser,
      }}
      >
      { props.children }
    </AuthContext.Provider>
  )
};

export default AuthContextProvider;
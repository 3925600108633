import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function PlantelesCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const [name, setName] = useState("");
  const [direccion, setDireccion] = useState("");
  const [telefono, setTelefono] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);


  const clear = () => {
    setName("");
    setDireccion('')
    setTelefono('')
    setValidaBoton(true)
  };

  const savePlanteles = (event) => {
    event.preventDefault();
    setValidaBoton(false)
    axios
      .post(
        URL_PLANTELES,
        {
          name,
          direccion,
          telefono,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Crear Plantel',
            detalle: `${name}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        clear();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);

      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  return (
    <>
      <div className="container">
        <h3 align="center">Crea un Plantel nuevo</h3>
        <Form onSubmit={savePlanteles}>
            <Label className="mr-sm-2">Plantel</Label>
            <Input
              className="col-sm-12"
              type="text"
              placeholder="Nombre del Plantel"
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <br />
            <Label className="mr-sm-2">Direccion</Label>
            <Input
              className="col-sm-12"
              type="text"
              placeholder="Direccion"
              value={direccion}
              required
              onChange={(e) => {
                setDireccion(e.target.value);
              }}
            />
            <br />
            <Label className="mr-sm-2">Telefono</Label>
            <Input
              className="col-sm-12"
              type="text"
              placeholder="Telefono"
              value={telefono}
              required
              onChange={(e) => {
                setTelefono(e.target.value);
              }}
            />
          <br />
          {validaBoton ? (
        <Button type="submit" className="btn btn-success">
        Guardar
      </Button>
      ):(
      <Button type="submit" className="btn btn-success" disabled>
        Guardar
      </Button>)}
          <Button
              href="/MenuCatalogos"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          <div>
            <SweetAlert
              show={text}
              title="Creado con Exito"
              onConfirm={() => {
                setText(false);
                window.location.href = "/MenuCatalogos";
              }}
            />
          </div>
        </Form>
      </div>
    </>
  );
}

export default PlantelesCreate;

import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import moment from 'moment';

function ReporteVentasCliente() {
  const { user } = useContext(AuthContext);
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [totalSubtotal, setTotalSubtotal] = useState(0);
  const [totalIva, setTotalIva] = useState(0);
  const [totalTotal, setTotalTotal] = useState(0);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");



  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_SURTIDOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSurtidos = response.data;
        let arrayTabla = allSurtidos
          .map((a) => {
            if(a.is_active =="Si"){
            return {
              clientes: a.clientes[0].nombre_comercial,     
              idClientes: a.clientes[0]._id,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
            };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        
        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idClientes"];
          groups[val] = groups[val] || {
            idClientes: item.idClientes,
            subTotal: 0,
            iva:0,
            total_general: 0,
          };
          groups[val].subTotal += item.subTotal;
          groups[val].iva += item.iva;
          groups[val].total_general += item.total_general;
          groups[val].idClientes = item.idClientes;
          groups[val].clientes = item.clientes;
          return groups;
        },[])

        let dataAgrupadoFinal = Object.values(agrupado);

        setComments(dataAgrupadoFinal);


        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);

  let totalImportePDF = 0;
  let subTotalPDF = 0;
  let totalIVAPDF = 0;

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        totalImportePDF = totalImportePDF + a.total_general;
        subTotalPDF = subTotalPDF + a.subTotal;
        totalIVAPDF = totalIVAPDF + a.iva;
        return [ a.clientes, new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.subTotal), new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.iva), new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.total_general)];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 23, 35, 25);
    doc.text(`Ventas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Cliente","SubTotal", "IVA", "Total"]],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(subTotalPDF),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalIVAPDF),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalImportePDF),

        ]
      ]
    });
    doc.save(`Ventas.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return {
          Cliente: a.clientes,
          SubTotal: a.subTotal,
          IVA: a.iva,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "Ventas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "Ventas",
        sheetFilter: ["Cliente", "SubTotal", "IVA", "Total"],
        sheetHeader: ["Cliente", "SubTotal", "IVA", "Total"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [ a.clientes, new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.subTotal), new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.iva), new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.total_general)];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 23, 35, 25);
    doc.text(`Ventas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Cliente", "SubTotal", "IVA", "Total"]],
      body: dataPDFLimpia,
      startY: 50,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Ventas",
          email: mailTo,
          fileName: "Ventas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Ventas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }


  const headers = [
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "SubTotal", field: "subTotal", sortable: true },
    { name: "IVA", field: "iva", sortable: true },
    { name: "Total", field: "total_general", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.vendedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.total_general.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }


    let car = computedComments.map((c) => parseFloat(c.subTotal));
    let TCar = car.reduce((t, subTotal, index) => t + subTotal, 0);
    setTotalSubtotal(TCar);

    let abo = computedComments.map((c) => parseFloat(c.iva));
    let TAbo = abo.reduce((t, iva, index) => t + iva, 0);
    setTotalIva(TAbo);

    let sal = computedComments.map((c) => parseFloat(c.total_general));
    let TSal = sal.reduce((t, total_general, index) => t + total_general, 0);
    setTotalTotal(TSal);

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_general" &&
      sorting.field != "subTotal" &&
      sorting.field != "iva"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (
        sorting.field == "total_general" ||
        sorting.field == "subTotal" ||
        sorting.field == "iva")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (
        sorting.field == "total_general" ||
        sorting.field == "subTotal" ||
        sorting.field == "iva")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
  ]);

  let totalImporteTabla = 0;
  let subTotalTabla = 0;
  let totalIVATabla = 0;

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_admin ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuReportes"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Reporte Ventas</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Ventas</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Clientes</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
           
            
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
      
                  totalImporteTabla = totalImporteTabla + a.total_general;
                  subTotalTabla = subTotalTabla + a.subTotal;
                  totalIVATabla = totalIVATabla + a.iva;
                  return (
                    <tr>
                      <td>{a.clientes}</td>
                      <td>{ new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.subTotal)}
                      </td>
                      <td>{ new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.iva)}
                      </td>
                      <td>{ new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.total_general)}
                      </td>

                    </tr>
                  );

              })}
              <tr>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(subTotalTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalIVATabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalImporteTabla)}
                </td>
              </tr>
              <tr>
                <td className="negrita" align="center">
                  TOTAL GENERAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSubtotal)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalIva)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalTotal)}
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />


      {loader}
    </>
  );
}

export default ReporteVentasCliente;

import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import CiclosCreate from "./CiclosCreate";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Baja from "../../Baja";
import Header from "../../../layout/Header/Header";

function ListadoCiclos() {
  const { user } = useContext(AuthContext);
  const URL_CICLOS = process.env.REACT_APP_URL_CICLOS;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const [ciclos, setCiclos] = useState([]);
  const [name, setName] = useState();
  const [fecha_inicio, setFechaInicio] = useState();
  const [fecha_fin, setFechaFin] = useState();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  // const [modalEdit, setModalEdit] = useState(false);
  // const toggleEdit = () => setModalEdit(!modalEdit);
  const [text, setText] = useState(false);

  const [idEdit, setIdEdit] = useState()

  useEffect(() => {
    axios
      .get(URL_CICLOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCiclos = response.data;
        setCiclos(allCiclos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function jalaInfo(idEdit, name, fecha_inicio, fecha_fin) {
    setName(name);
    setIdEdit(idEdit);
    setFechaInicio(fecha_inicio);
    setFechaFin(fecha_fin);
    toggle();
}

  function editCiclo(event) {
    event.preventDefault();
    const URL_CICLOS_EDIT = `${process.env.REACT_APP_URL_CICLOS}/${idEdit}`;
    axios
      .patch(
        URL_CICLOS_EDIT,
        {
          name,
          fecha_inicio,
          fecha_fin,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Ciclo",
            detalle: name,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggle();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
        { user.menu_ciclos ? (
          <div className="row">
            {
              <div className="col-md-6 col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h3 align="center">Ciclos</h3>
                    <Table
                      size="sm"
                      striped
                      borderless
                      className="table-responsive-xl"
                    >
                      <thead>
                        <tr>
                          <th className="tituloTabla">Nombre</th>
                          <th className="tituloTabla">Fecha Inicio</th>
                          <th className="tituloTabla">Fecha Fin</th>
                          <th className="tituloTabla">Editar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ciclos
                          .sort((a, b) => (a.name < b.name ? 1 : -1))
                          .map((a) => {
                            return (
                              <tr>
                                <td>{a.name}</td>
                                <td>{a.fecha_inicio}</td>
                                <td>{a.fecha_fin}</td>
                                <td>
                                  <Button
                                    color="info"
                                    id="Editar"
                                    size="sm"
                                    onClick={(e) =>
                                      jalaInfo(
                                        a._id,
                                        a.name,
                                        a.fecha_inicio,
                                        a.fecha_fin
                                      )
                                    }
                                  >
                                    <i class="far fa-edit"></i>{" "}
                                  </Button>
                                  <Baja
                                    idStatus={a._id}
                                    is_active={a.is_active}
                                    URL_BAJA={
                                      process.env.REACT_APP_URL_CICLOS
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            }
            <div className="col-md-6 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <CiclosCreate />
                </div>
              </div>
            </div>
          </div>
        ) : undefined}
      </div>
      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Editar Ciclo</ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Nombre</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={name}
            required
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <Label>Fecha Inicio</Label>
          <Input
            type="date"
            value={fecha_inicio}
            required
            onChange={(e) => {
              setFechaInicio(e.target.value);
            }}
          />
          <br />
          <Label>Fecha Fin</Label>
          <Input
            type="date"
            value={fecha_fin}
            required
            onChange={(e) => {
              setFechaFin(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editCiclo}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ListadoCiclos;

import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import axios from 'axios'

function ClientesInfo(props) {
    const URL_CLIENTES_INFO = `${process.env.REACT_APP_URL_CLIENTES}/${props.idCliente}`;
    const [cliente, setCliente] = useState([]);
    const [grupo, setGrupo] = useState([]);
    const [colaborador, setColaborador] =useState([])
  
    useEffect(() => {
      axios
        .get(URL_CLIENTES_INFO, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allColaborador = response.data.colaboradores[0]
          setColaborador(allColaborador);
          let allGrupo = response.data.grupos[0]
          setGrupo(allGrupo);
          let allCliente = response.data
          setCliente(allCliente);
        })
        .catch((err) => {
          console.log(err);
        });
    },[]);

    return (
        <>

        <div className="card container">
           <Table size="sm" borderless>
             <tbody>
             <tr><td>Nombre</td><td>{cliente.nombre} {cliente.apellido} </td></tr>
             <tr><td>Nombre Comercial</td><td>{cliente.nombre_comercial}</td></tr>
             <tr><td>Telefono</td><td>{cliente.telefono}</td></tr>
                <tr><td>Email</td><td>{cliente.email}</td></tr>
                <tr><td>Forma de Pago</td><td>{cliente.forma_pago}</td></tr>
                <tr><td>Razon Social</td><td>{cliente.razon_social}</td></tr>
                <tr><td>Grupo</td><td>{grupo.name}</td></tr>
                <tr><td>Direccion</td><td>{cliente.calle} {cliente.numero_ext} {cliente.numero_int} {cliente.colonia}</td></tr>
                <tr><td>Cobrador</td><td>{colaborador.nombre + " " + colaborador.apellido} </td></tr>
                <tr><td>Observaciones</td><td>{cliente.observaciones}</td></tr>
                </tbody> 
                </Table>
            </div>
   
        </>
    )
}

export default ClientesInfo

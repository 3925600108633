import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import TablaFaltasColaboradores from "./TablaFaltasColaboradores";
import FaltasColaboradoresCreate from "./FaltasColaboradoresCreate";
import axios from "axios";
import { Table, Input, Row, Col, Label, Button, ButtonGroup, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function ListadoFaltasColaboradores() {
  const { user } = useContext(AuthContext);
  const URL_FALTAS_COLABORADORES =
    process.env.REACT_APP_URL_FALTAS_COLABORADORES;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const [colaboradores, setColaboradores] = useState([]);
  const [faltasColaboradores, setFaltasColaboradores] = useState([]);
  const [planteles, setPlanteles] = useState([]);
  const [selectedPlantel, setSelectedPlantel] = useState(0);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(0);
  const [justificada, setJustificada] = useState("");
  const [text, setText] = useState(false);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState('');

  useEffect(() => {
    axios
      .get(URL_FALTAS_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allFaltasColaboradores = response.data;
        setFaltasColaboradores(allFaltasColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, selectedPlantel]);

  function PDFTabla() {
    const logo = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    const data = faltasColaboradores.map((c) => {
      if (
        (user.planteles == PLANTELES_GENERAL && user.areas == AREAS_GENERAL) &&
        (selectedPlantel == 0 ||
          selectedPlantel ==
            c.colaboradores[0].planteles[0]._id) &&
            (selectedArea == 0 ||
              selectedArea == c.colaboradores[0].areas[0]._id) &&
        (justificada == 0 || justificada == c.justificada) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedColaborador == 0 ||
          selectedColaborador == c.colaboradores[0]._id)
      ) {
        return [
          [c.colaboradores[0].nombre + " " + c.colaboradores[0].apellido],
          c.colaboradores[0].planteles[0].name,
          c.colaboradores[0].areas[0].name,
          c.fecha,
          c.justificada,
          c.observaciones,
        ];
      } else if (
        (c.colaboradores[0].planteles[0]._id == user.planteles && c.colaboradores[0].areas[0]._id == user.areas) &&
        (justificada == 0 || justificada == c.justificada) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedColaborador == 0 ||
          selectedColaborador == c.colaboradores[0]._id)
      ) {
        return [
          [c.colaboradores[0].nombre + " " + c.colaboradores[0].apellido],
          c.colaboradores[0].planteles[0].name,
          c.colaboradores[0].areas[0].name,
          c.fecha,
          c.justificada,
          c.observaciones,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text("Detalle de Gastos", 20, 25);
    doc.autoTable({
      head: [
        [
          "Colaborador",
          "Plantel",
          "Area",
          "Fecha",
          "Justificada",
          "Observaciones",
        ],
      ],
      body: dataPDFLimpia,
      startY: 30,
      showFoot: "lastPage",
    });
    doc.save("Faltas.pdf");
  }

  function excel() {
    const dataExcel = faltasColaboradores.map((a) => {
      if (
        (user.planteles == PLANTELES_GENERAL && user.areas == AREAS_GENERAL) &&
        (selectedPlantel == 0 ||
          selectedPlantel ==
            a.colaboradores[0].planteles[0]._id) &&
            (selectedArea == 0 ||
              selectedArea == a.colaboradores[0].areas[0]._id) &&
        (justificada == 0 || justificada == a.justificada) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedColaborador == 0 ||
          selectedColaborador == a.colaboradores[0]._id)
      ) {
        return {
          Nombre: a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
          Plantel: a.colaboradores[0].planteles[0].name,
          Area: a.colaboradores[0].areas[0].name,
          Fecha: a.fecha,
          Justificada: a.justificada,
          Observaciones: a.observaciones,
        };
      } else if(
        (a.colaboradores[0].planteles[0]._id == user.planteles && a.colaboradores[0].areas[0]._id == user.areas) &&
        (justificada == 0 || justificada == a.justificada) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedColaborador == 0 ||
          selectedColaborador == a.colaboradores[0]._id)
      ) {
        return {
          Nombre: a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
          Plantel: a.colaboradores[0].planteles[0].name,
          Area: a.colaboradores[0].areas[0].name,
          Fecha: a.fecha,
          Justificada: a.justificada,
          Observaciones: a.observaciones,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "Faltas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "Faltas",
        sheetFilter: ["Nombre", "Plantel", "Area", "Fecha", "Justificada", "Observaciones"],
        sheetHeader: ["Nombre", "Plantel", "Area", "Fecha", "Justificada", "Observaciones"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail(){
    const logo = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    const data = faltasColaboradores.map((c) => {
      if (
        (user.planteles == PLANTELES_GENERAL && user.areas == AREAS_GENERAL) &&
        (selectedPlantel == 0 ||
          selectedPlantel ==
            c.colaboradores[0].planteles[0]._id) &&
            (selectedArea == 0 ||
              selectedArea == c.colaboradores[0].areas[0]._id) &&
        (justificada == 0 || justificada == c.justificada) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedColaborador == 0 ||
          selectedColaborador == c.colaboradores[0]._id)
      ) {
        return [
          [c.colaboradores[0].nombre + " " + c.colaboradores[0].apellido],
          c.colaboradores[0].planteles[0].name,
          c.colaboradores[0].areas[0].name,
          c.fecha,
          c.justificada,
          c.observaciones,
        ];
      } else if (
        (c.colaboradores[0].planteles[0]._id == user.planteles && c.colaboradores[0].areas[0]._id == user.areas) &&
        (justificada == 0 || justificada == c.justificada) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedColaborador == 0 ||
          selectedColaborador == c.colaboradores[0]._id)
      ) {
        return [
          [c.colaboradores[0].nombre + " " + c.colaboradores[0].apellido],
          c.colaboradores[0].planteles[0].name,
          c.colaboradores[0].areas[0].name,
          c.fecha,
          c.justificada,
          c.observaciones,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text("Detalle de Gastos", 20, 25);
    doc.autoTable({
      head: [
        [
          "Colaborador",
          "Plantel",
          "Area",
          "Fecha",
          "Justificada",
          "Observaciones",
        ],
      ],
      body: dataPDFLimpia,
      startY: 30,
      showFoot: "lastPage",
    });
    var att = doc.output('arraybuffer');
    var base64File = encode.encode(att, 'base64')

     // Envia el Mail
     axios
     .post(
       URL_SEND_MAIL_ATT,
       {
         subject:'Faltas Empleados',
         email: mailTo,
         fileName: 'Faltas.pdf',
         att: base64File,
         text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de faltas de empleados.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`
       },
       {
         headers: {
           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
         },
       }
     )
     .then(() => {
       toggleMail()
       Swal.fire("Good job!", "Enviado con exito", "success");
setTimeout(() => {
      window.location.reload();
    }, 1000);
      })
     .catch((error) => {
       Swal.fire({
         icon: "error",
         title: "Oops...",
         text: "Something went wrong!",
         footer: `${error.response.data}`,
       });
       console.log(error);
     })
  }

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
        {user.menu_empleados ? (
          <div className="row">
            {
              <div className="col-lg-12 col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <Row>
                      <Col md={7}>
                        {user.menu_empleados ? (
                          <Button
                            size="sm"
                            href="/FaltasColaboradoresCreate"
                            className="btn btn-success"
                            id="botonListado"
                          >
                            Registrar
                          </Button>
                        ) : (
                          <Button
                            size="sm"
                            disabled
                            className="btn btn-success"
                            id="botonListado"
                          >
                            Registrar
                          </Button>
                        )}
                        <Button
                          size="sm"
                          href="/MenuNominas"
                          className="btn btn-danger"
                          id="botonListado"
                        >
                          Regresar
                        </Button>
                      </Col>
                      <Col md={5}>
                        <ButtonGroup id="logoutBoton">
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={PDFTabla}
                          >
                            PDF <i class="far fa-file-pdf"></i>
                          </Button>
                          <Button
                            size="sm"
                            className="btn"
                            color="info"
                            onClick={toggleMail}
                          >
                            eMail <i class="fas fa-at"></i>
                          </Button>

                          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                      <ModalHeader toggle={toggleMail}><h4>Enviar Listado de Faltas</h4></ModalHeader>
                      <ModalBody>
                        <Label className="mr-sm-2">Email</Label>
                        <Input
                          className="col-sm-12"
                          type="text"
                          value={mailTo}
                          required
                          onChange={(e) => {
                            setMailTo(e.target.value);
                          }}
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          size="sm"
                          color="success"
                          onClick={enviaMail}
                        >
                          Enviar
                        </Button>
                      </ModalFooter>
                    </Modal>


                          <Button
                            size="sm"
                            className="btn"
                            color="primary"
                            onClick={excel}
                          >
                            Excel <i class="far fa-file-excel"></i>
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                    <h3 align="center">Faltas de Empleados</h3>
                    <Row>
                      <Col md={2}>
                        <Label>Fecha Inicio</Label>
                        <Input
                          bsSize="sm"
                          type="date"
                          value={selectedFechaInicio}
                          onChange={(e) => {
                            setSelectedFechaInicio(e.target.value);
                          }}
                        />
                      </Col>
                      <Col md={2}>
                        <Label>Fecha Fin</Label>
                        <Input
                          bsSize="sm"
                          type="date"
                          value={selectedFechaFin}
                          onChange={(e) => {
                            setSelectedFechaFin(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Table size="sm" striped borderless className="table-responsive-xl">
                      <thead>
                        <tr>
                          <th
                            style={{ paddingBottom: "0px" }}
                            className="tituloTabla"
                          >
                            Nombre
                          </th>
                          <th
                            style={{ paddingBottom: "0px" }}
                            className="tituloTabla"
                          >
                            Plantel
                          </th>
                          <th
                            style={{ paddingBottom: "0px" }}
                            className="tituloTabla"
                          >
                            Area
                          </th>
                          <th
                            style={{ paddingBottom: "0px" }}
                            className="tituloTabla"
                          >
                            Fecha
                          </th>
                          <th
                            style={{ paddingBottom: "0px" }}
                            className="tituloTabla"
                          >
                            Justificada
                          </th>
                          <th
                            style={{ paddingBottom: "0px" }}
                            className="tituloTabla"
                          >
                            Editar
                          </th>
                        </tr>
                        <tr>
                          <td style={{ paddingTop: "0px" }}>
                          <Input
                    bsSize="sm"
                    type="select"
                    value={selectedColaborador}
                    required
                    onChange={(e) => {
                      setSelectedColaborador(e.target.value);
                    }}
                  >
                    <option value="0">Selecciona un Empleado</option>
                    {colaboradores
                      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                      .map((a) => {
                        if(user.planteles == PLANTELES_GENERAL &&
                          user.areas == AREAS_GENERAL){
                      return <option value={a._id}>{a.nombre} {a.apellido}</option>
                      } else if(user.planteles == a.planteles[0]._id && user.areas == a.areas[0]._id){
                        return <option value={a._id}>{a.nombre} {a.apellido}</option>
                      }
                      })}
                  </Input>
                          </td>

                          {user.planteles == PLANTELES_GENERAL ? (
                            <td style={{ paddingTop: "0px" }}>
                              <Input
                                bsSize="sm"
                                type="select"
                                value={selectedPlantel}
                                onChange={(e) =>
                                  setSelectedPlantel(e.target.value)
                                }
                              >
                                <option value="0">Selecciona</option>
                                {planteles
                                  .sort((a, b) => a.name - b.name)
                                  .map((a) => {
                                    if (a._id != PLANTELES_GENERAL) {
                                      return (
                                        <option value={a._id}>{a.name}</option>
                                      );
                                    }
                                  })}
                              </Input>
                            </td>
                          ) : undefined}
                          {user.areas == AREAS_GENERAL ? (
                            <td style={{ paddingTop: "0px" }}>
                              <Input
                                bsSize="sm"
                                type="select"
                                value={selectedArea}
                                onChange={(e) =>
                                  setSelectedArea(e.target.value)
                                }
                              >
                                <option value="0">Selecciona</option>
                                {areas
                                  .sort((a, b) => a.name - b.name)
                                  .map((a) => {
                                    if (a._id != AREAS_GENERAL) {
                                      return (
                                        <option value={a._id}>{a.name}</option>
                                      );
                                    }
                                  })}
                              </Input>
                            </td>
                          ) : undefined}
                          <td></td>
                          <td style={{ paddingTop: "0px" }}>
                            <Input
                              bsSize="sm"
                              type="select"
                              value={justificada}
                              onChange={(e) => {
                                setJustificada(e.target.value);
                              }}
                            >
                              <option value="0">Selecciona</option>
                              <option value="Si">Si</option>
                              <option value="No">No</option>
                            </Input>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {user.planteles == PLANTELES_GENERAL &&
                        user.areas == AREAS_GENERAL
                          ? faltasColaboradores
                              .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
                              .map((c) => {
                                if (
                                  (selectedPlantel == 0 ||
                                    selectedPlantel ==
                                      c.colaboradores[0].planteles[0]._id) &&
                                  (selectedArea == 0 ||
                                    selectedArea ==
                                      c.colaboradores[0].areas[0]._id) &&
                                  (justificada == 0 ||
                                    justificada == c.justificada) &&
                                  (selectedFechaInicio == 0 ||
                                    selectedFechaInicio <= c.fecha) &&
                                  (selectedFechaFin == 0 ||
                                    selectedFechaFin >= c.fecha) &&
                                  (selectedColaborador == 0 ||
                                    selectedColaborador == c.colaboradores[0]._id)
                                ) {
                                  return (
                                    <tr>
                                      <TablaFaltasColaboradores
                                        nombre={c.colaboradores[0].nombre}
                                        apellido={c.colaboradores[0].apellido}
                                        area={c.colaboradores[0].areas[0].name}
                                        plantel={
                                          c.colaboradores[0].planteles[0].name
                                        }
                                        idPlantel={
                                          c.colaboradores[0].planteles[0]._id
                                        }
                                        idAreas={
                                          c.colaboradores[0].areas[0]._id
                                        }
                                        _id={c._id}
                                        fecha={c.fecha}
                                        justificada={c.justificada}
                                        observaciones={c.observaciones}
                                        idColaborador={c.colaboradores[0]._id}
                                      />
                                    </tr>
                                  );
                                }
                              })
                          : faltasColaboradores.map((c) => {
                              if (
                                user.planteles ==
                                  c.colaboradores[0].planteles[0]._id &&
                                user.areas == c.colaboradores[0].areas[0]._id &&
                                (justificada == 0 ||
                                  justificada == c.justificada) &&
                                (selectedFechaInicio == 0 ||
                                  selectedFechaInicio <= c.fecha) &&
                                (selectedFechaFin == 0 ||
                                  selectedFechaFin >= c.fecha) &&
                                  (selectedColaborador == 0 ||
                                    selectedColaborador == c.colaboradores[0]._id)
                              ) {
                                return (
                                  <tr>
                                    <TablaFaltasColaboradores
                                      nombre={c.colaboradores[0].nombre}
                                      apellido={c.colaboradores[0].apellido}
                                      area={c.colaboradores[0].areas[0].name}
                                      plantel={
                                        c.colaboradores[0].planteles[0].name
                                      }
                                      idPlantel={
                                        c.colaboradores[0].planteles[0]._id
                                      }
                                      idAreas={c.colaboradores[0].areas[0]._id}
                                      _id={c._id}
                                      fecha={c.fecha}
                                      justificada={c.justificada}
                                      observaciones={c.observaciones}
                                    />
                                  </tr>
                                );
                              }
                            })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            }
          </div>
        ) : undefined}
      </div>
      <br />
      
    </>
  );
}

export default ListadoFaltasColaboradores;

import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function EmpresasCreate() {
  const { user } = useContext(AuthContext);
  const URL_EMPRESAS = process.env.REACT_APP_URL_EMPRESAS;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const [name, setName] = useState("");
  const [prefijo, setPrefijo] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [rfc, setRfc] = useState("");
  const [cp, setCP] = useState("");
  const [regimenFiscal, setRegimenFiscal] = useState("");
  const [direccionFiscal, setDireccionFiscal] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  const clear = () => {
    setName("");
  };

  const saveEmpresas = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    axios
      .post(
        URL_EMPRESAS,
        {
          name,
          prefijo,
          razonSocial,
          rfc,
          cp,
          regimenFiscal,
          direccionFiscal,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Empresa",
            detalle: name,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        clear();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
        setValidaBoton(true);
      });
  };

  return (
    <>
      <div className="container">
        <h3 align="center">Crea una Empresa nueva</h3>
        <Form onSubmit={saveEmpresas}>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label className="mr-sm-2">
              Empresa
            </Label>
            <Input
              type="text"
              placeholder="Nombre de la Empresa"
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
              <Label className="mr-sm-2">
              Prefijo
            </Label>
            <Input
              type="text"
              placeholder="Prefijo"
              value={prefijo}
              required
              onChange={(e) => {
                setPrefijo(e.target.value);
              }}
            />
            <Label className="mr-sm-2">
              Razon Social
            </Label>
            <Input
              type="text" 
              placeholder="Razon Social"
              value={razonSocial}
              required
              onChange={(e) => {
                setRazonSocial(e.target.value);
              }}
            />
            <Label className="mr-sm-2">
              RFC
            </Label>
            <Input
              type="text" 
              placeholder="RFC"
              value={rfc}
              required
              onChange={(e) => {
                setRfc(e.target.value);
              }}
            />
            <Label className="mr-sm-2">
              Regimen Fiscal
            </Label>
            <Input
              type="select" 
              placeholder="Regimen Fiscal"
              value={regimenFiscal}
              required
              onChange={(e) => {
                setRegimenFiscal(e.target.value);
              }}
            >
               <option value="">Reg Fiscal</option>
                  <option value="0">0</option>
                  <option value="601">601 General de Ley Personas Morales</option>
                  <option value="603">603 Personas Morales con Fines no Lucrativos</option>
                  <option value="605">605 Sueldos y Salarios e Ingresos Asimilados al Salario</option>
                  <option value="608">608 Demas Ingresos</option>
                  <option value="612">612 Personas Físicas con Actividades Empresariales y Profesionales</option>
                  <option value="616">616 Sin Obligacion Fiscal</option>
                  <option value="621">621 Incorporacion Fiscal</option>
                  <option value="624">624 Régimen de los Coordinados</option>
                  <option value="625">625 Reg. de las act. emp. cpn ingresos a traves de plat. tec.</option>
                  <option value="626">626 Regimen simplificado de Confianza</option>
              </Input>
            <Label className="mr-sm-2">
              Direccion Fiscal
            </Label>
            <Input
              type="text" 
              placeholder="Direccion Fiscal"
              value={direccionFiscal}
              required
              onChange={(e) => {
                setDireccionFiscal(e.target.value);
              }}
            />
             <Label className="mr-sm-2">
              CP
            </Label>
            <Input
              type="text" 
              placeholder="CP"
              value={cp}
              required
              onChange={(e) => {
                setCP(e.target.value);
              }}
            />
          </FormGroup>
          <Row className="container">
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <div>
              <SweetAlert
                show={text}
                title="Creado con Exito"
                onConfirm={() => {
                  setText(false);
                }}
              />
            </div>
            <Button color="danger" href="/MenuFiscal">
              Regresar
            </Button>
            </Row>
        </Form>
      </div>
    </>
  );
}

export default EmpresasCreate;

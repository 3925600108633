import React, { useState } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function AutorizadoMultiple(props) {
  const [text, setText] = useState(false);
  const URL_AUTORIZADO = `${props.URL_AUTORIZADO}/${props.idStatus}`;
  const URL_AUTORIZADO_HIJO = `${props.hijo}/${props.idStatus}`;

  function desautorizar() {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se quitara la autorizacion!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, quitar autorizacion!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_AUTORIZADO,
            {
              autorizado: "No",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
            .patch(
              URL_AUTORIZADO_HIJO,
              {
                autorizado: "No",
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            ).then(()=>{
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Se quito la autorizacion",
                showConfirmButton: false,
                timer: 2000,
              });
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }


  function autorizar() {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se va a autorizar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, autorizar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_AUTORIZADO,
            {
              autorizado: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
            .patch(
              URL_AUTORIZADO_HIJO,
              {
                autorizado: "Si",
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            ).then(()=>{
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Se Autorizo",
                showConfirmButton: false,
                timer: 2000,
              });
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  return (
    <>
      {props.autorizado == "Si" ? (
        <span>
          <Button
            color="primary"
            id="Baja"
            onClick={desautorizar}
            size="sm"
          >
          <i class="fas fa-user-check"></i>
          </Button>
        </span>
      ) : (
        <span>
          <Button color="danger" onClick={autorizar} size="sm">
            <i class="fas fa-user-lock"></i>
          </Button>
        </span>
      )}
    </>
  );
}


export default AutorizadoMultiple;


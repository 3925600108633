import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import axios from 'axios'

function ProveedoresInfo(props) {
    const URL_PROVEEDORES_INFO = `${process.env.REACT_APP_URL_PROVEEDORES}/${props.idProveedor}`;
    const [proveedor, setProveedor] = useState([]);
  console.log(URL_PROVEEDORES_INFO)
    useEffect(() => {
      axios
        .get(URL_PROVEEDORES_INFO, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allProveedor = response.data
          setProveedor(allProveedor);
        })
        .catch((err) => {
          console.log(err);
        });
    },[]);
    console.log('proveedor', proveedor)

    return (
        <>
         <div className="content">
        <div className="card container">
           <Table size="sm" borderless>
             <tbody>
             <tr><td>Nombre Comercial</td><td>{proveedor.nombre_comercial}</td></tr>
             <tr><td>Razon Social</td><td>{proveedor.razon_social}</td></tr>
             <tr><td>Direccion</td><td>{proveedor.calle} {proveedor.numero_ext} {proveedor.numero_int} {proveedor.colonia}</td></tr>
             <tr><td>Contacto</td><td>{proveedor.contacto}</td></tr>
             <tr><td>Telefono</td><td>{proveedor.telefono}</td></tr>
             <tr><td>email</td><td>{proveedor.email}</td></tr>
                </tbody> 
                </Table>
            </div>
            </div>
        </>
    )
}

export default ProveedoresInfo

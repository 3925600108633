import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ComprasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_COMPRAS = process.env.REACT_APP_URL_COMPRAS;
  const URL_COMPRAS_ACUMULADOS = process.env.REACT_APP_URL_COMPRAS_ACUMULADOS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_NOTAS_CXP = process.env.REACT_APP_URL_NOTAS_CXP;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");


  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [colores, setColores] = useState([]);
  const [fecha, setFecha] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  const [total_kilos, setTotalKilos] = useState(0);
  const [total_metros, setTotalMetros] = useState(0);
  const [total_rollos, setTotalRollos] = useState(0);
  const [total_costo, setTotalCosto] = useState(0);
  const [articulos, setArticulos] = useState([]);
  const [totalCaptura, setTotalCaptura] = useState(1);
  const [cantidadAgrupado, setCantidadAgrupado] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      unidad: "",
      colores: "",
      arrayColoresArticulo:[],
      cantidad: 0,
      clave:"0",
      captura:1,
      costo:0,
      total:0,
      articulosName: "",
      coloresName: "",
      rollos: parseFloat(1)
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {

  
      axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveCompraNueva = async (event) => {
    event.preventDefault();
    if (fecha != "" && observaciones != "")  {
      setValidaBoton(false);
      toggleProgreso();

      let totalRollos = inputFields.length + cantidadAgrupado.length

      try {
        await axios
          .post(
            `${URL_COMPRAS}Directa`,
            {
              fecha,
              observaciones,
              total_kilos,
              total_metros: total_metros.toFixed(2),
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {

            axios
            .post(
              `${URL_NOTAS_CXP}`,
              {
                proveedores: selectedProveedor,
                compras: data.data._id,
                total_cantidad: parseFloat(total_metros) + parseFloat(total_kilos),
                total_costo: parseFloat(total_costo).toFixed(2),
                saldo: parseFloat(total_costo).toFixed(2),
                calidad:"No",
                fecha
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem(
                    "app_token"
                  )}`,
                },
              }
            )



            inputFields.map((a) => {
              if (a.articulos != "" && a.cantidad != 0) {
                axios
                  .post(
                    `${URL_INVENTARIOS}`,
                    {
                      compras: data.data._id,
                      articulos: a.articulos,
                      colores: a.colores,
                      cantidad: parseFloat(a.cantidad),
                      clave: a.clave,
                      captura: a.captura,
                      costo: a.costo,
                      proveedores: selectedProveedor
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Compra",
                            detalle: `Compra Directa`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              } else {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Compra",
                        detalle: `Compra Directa`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });
            // Termina primer map



            // Pegamos a Compras Acumulado

            cantidadAgrupado.map((w) => {
              axios
                .post(
                  `${URL_COMPRAS_ACUMULADOS}`,
                  {
                    fecha,
                    compras: data.data._id,
                    articulos: w.articulos,
                    colores: w.colores,
                    cantidad: w.cantidad,
                    precio:w.total
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios.post(
                      URL_LOGS,
                      {
                        tipo: "Crear Compra Directa",
                        detalle: `Compra Directa`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                        },
                      }
                    )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });

          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event){
    articulos.map((a)=>{
      if(a._id == event.target.value){
        let idArticulo = a._id
        let unidadArticulo = a.unidad
        let coloresArticulo = a.colores
        let articulosName = a.nombre
        handleChangeInputArticulo(id, idArticulo, unidadArticulo, coloresArticulo, articulosName)
      }
    })
  }

  const handleChangeInputArticulo = (id, idArticulo, unidadArticulo, coloresArticulo, articulosName) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.unidad = unidadArticulo
        i.arrayColoresArticulo = coloresArticulo
        i.articulosName = articulosName
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };

  function BuscaColor(id, event){
    colores.map((a)=>{
      if(a._id == event.target.value){
        let idColor = a._id
        let coloresName = a.name
        handleChangeInputColores(id, idColor, coloresName)
      }
    })
  }

  const handleChangeInputColores = (id, idColor, coloresName) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.colores = idColor;
        i.coloresName = coloresName
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };

  const handleChangeInputCosto = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value)
        i.total = parseFloat(event.target.value) * i.cantidad
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.total = parseFloat(event.target.value) * i.costo
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

 

  function ActualizaTotales(){
    let mts = inputFields.filter(e=>e.unidad == "Metros")
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = inputFields.filter(e=>e.unidad == "Kilos")
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);
    let costo = inputFields.map((c) => parseFloat(c.total));
    let TCosto = costo.reduce((t, total, index) => t + total, 0);
    setTotalCosto(TCosto);

    let totalRollos = inputFields.map((c) => parseFloat(c.rollos));
    let Rollos = totalRollos.reduce((t, total, index) => t + total, 0);
    setTotalRollos(Rollos);

    let agrupado = inputFields.reduce(function (groups, item) {
      const val = item["articulos"] + item["colores"];
      groups[val] = groups[val] || {
        articulos: item.articulos,
        colores: item.colores,
        cantidad: 0,
        total:0,
        rollos:0
      };
      groups[val].cantidad += item.cantidad;
      groups[val].rollos += item.rollos;
      groups[val].total += item.total;
      groups[val].id = item.id;
      groups[val].articulos = item.articulos;
      groups[val].articulosName = item.articulosName;
      groups[val].coloresName = item.coloresName;
      groups[val].unidad = item.unidad;
      groups[val].colores = item.colores;
      groups[val].clave = item.clave;
      groups[val].costo = item.costo;
      return groups;
    }, []);

    let CA = Object.values(agrupado);
    setCantidadAgrupado(CA);
}

const handleAddFields = () => {
  let TC = totalCaptura +1
  const ultimo = inputFields[inputFields.length-1];
  
  setTotalCaptura(TC)
  setInputFields([
    ...inputFields,
    {
      id: uuidv4(),
      articulos: ultimo.articulos,
      unidad:ultimo.unidad,
      colores: ultimo.colores,
      arrayColoresArticulo: ultimo.arrayColoresArticulo,
      cantidad: 0,
      clave: ultimo.clave,
      captura:TC,
      costo:ultimo.costo,
      total:0,
      articulosName: ultimo.articulosName,
      coloresName: ultimo.coloresName,
      rollos: parseFloat(1)
    },
  ]);
};


const handleRemoveFields = (id) => {
  const values = [...inputFields];
  values.splice(
    values.findIndex((value) => value.id === id),
    1
  );
  setInputFields(values);
  let mts = values.filter(e=>e.unidad == "Metros")
  let totalMts = mts.map((c) => parseFloat(c.cantidad));
  let TM = totalMts.reduce((t, total, index) => t + total, 0);
  setTotalMetros(TM);
  let KG = values.filter(e=>e.unidad == "Kilos")
  let totalKG = KG.map((c) => parseFloat(c.cantidad));
  let TK = totalKG.reduce((t, total, index) => t + total, 0);
  setTotalKilos(TK);

};

console.table(inputFields)
  return (
    <>
      <Header />
      <br />
      <br />
      {user.compras_create ? (
        <div className="card container col-10">
          <h3 align="center">Nueva Entrada</h3>
          <Form onSubmit={saveCompraNueva}>
            <Row>
            <Col md={3}>
                <Label className="mr-sm-2">Proveedor</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedProveedor}
                  required
                  onChange={(e) => {
                    setSelectedProveedor(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Proveedor</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
            
              <Col md={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
           
            <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Articulos</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Color</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Rollos</Label>
                </Col>
              </Row>
                {cantidadAgrupado.map((ag)=>{
                  return (
                    <>
                    <Row>
                    <Col md={2}>
                    <Label className="mr-sm-2">{ag.articulosName}</Label>
                      </Col> 
                    <Col md={2}>
                    <Label className="mr-sm-2">{ag.coloresName}</Label>
                      </Col>
                    <Col md={2}>
                    <Label className="mr-sm-2">{ag.cantidad}</Label>
                      </Col> 
                    <Col md={2}>
                    <Label className="mr-sm-2">{ag.rollos}</Label>
                      </Col> 
                    </Row>
                    </>
                    )
                })}



              <Row>
              <Col md={12}>
              <h4 id="logoutBoton">
              TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)} kg. / {new Intl.NumberFormat("en-US").format(total_metros)} mts. / {new Intl.NumberFormat("en-US").format(total_rollos)} rollos
              </h4>
              </Col>
            </Row>

                        {/* Tabla Articulos */}

                        <Row>
                <Col md={3}>
                  <Label className="mr-sm-2">Articulos</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Unidad</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Color</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Costo</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Clave</Label>
                </Col>
              </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.articulos}
                      required
                      onChange={(event) =>
                        BuscaArticulo(inputField.id, event)
                      }
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulos
                      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                               {a.nombre}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>

                  <Col md={1}>
                        <Input
                         bsSize="sm"
                          name="unidad"
                          type="string"
                          value={inputField.unidad}
                          disabled
                        >
                        </Input>
                      </Col>
                      <Col md={2}>
                      <Input
                        bsSize="sm"
                        name="colores"
                        type="select"
                        value={inputField.colores}
                        required
                        onChange={(event) =>
                          BuscaColor(inputField.id, event)
                        }
                      >
                        <option value="">Selecciona un Color</option>
                        {inputField.arrayColoresArticulo
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                    </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="costo"
                      type="number"
                      value={inputField.costo}
                      onChange={(event) =>
                        handleChangeInputCosto(inputField.id, event)
                      }
                    />
                  </Col>


                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="clave"
                        type="text"
                        placeholder="Clave"
                        value={inputField.clave}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                
                    <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(inputField.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button 
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1">
                      <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
          
                </Row>
              </div>
            ))}
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)} kg. / {new Intl.NumberFormat("en-US").format(total_metros)} mts. / {new Intl.NumberFormat("en-US").format(total_rollos)} rollos
                </h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoCompras"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
         <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navefriendsor.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ComprasCreate;

import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

function FaltasColaboradoresCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_FALTAS_COLABORADORES = process.env.REACT_APP_URL_FALTAS_COLABORADORES;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [fecha, setFecha] = useState("");
  const [justificada, setJustificada] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  const clear = () => {
    setFecha("");
    setJustificada("");
    setSelectedColaborador("");
    setObservaciones("");
  };
  useEffect(() => {
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveFaltasColaboradores = (event) => {
    event.preventDefault();
    setValidaBoton(false)
    axios
      .post(
        URL_FALTAS_COLABORADORES,
        {
          fecha,
          justificada,
          observaciones,
          colaboradores: selectedColaborador,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Falta Colaborador",
            detalle: `${selectedColaborador} / ${fecha}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        clear();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  return (
    <>
    <Header />
    <br />
      <div className="container">
        {user.menu_empleados ? (
           <div className="container">
           <div className="card container col-sm-6">
        <h3 align="center">Registra una falta</h3>
        <Form onSubmit={saveFaltasColaboradores}>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label className="mr-sm-2">Empleado</Label>
            <Input
              className="col-sm-12"
              type="select"
              value={selectedColaborador}
              required
              onChange={(e) => {
                setSelectedColaborador(e.target.value);
              }}
            >
              <option value="0">Selecciona un Empleado</option>
              {colaboradores
                .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                .map((a) => {
                  if (
                    user.planteles == PLANTELES_GENERAL &&
                    user.areas == AREAS_GENERAL
                  ) {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  } else if (
                    user.planteles == a.planteles[0]._id &&
                    user.areas == a.areas[0]._id
                  ) {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  }
                })}
            </Input>
            <Label for="exampleEmail" className="mr-sm-2">
              Fecha
            </Label>
            <Input
              type="date"
              value={fecha}
              required
              onChange={(e) => {
                setFecha(e.target.value);
              }}
            />
            <Label for="exampleEmail" className="mr-sm-2">
              Justificada
            </Label>
            <Input
              type="select"
              placeholder="Justificada"
              value={justificada}
              required
              onChange={(e) => {
                setJustificada(e.target.value);
              }}
            >
              <option value="0">Justificada</option>
              <option value="Si">Si</option>
              <option value="No">No</option>
            </Input>
            <Label for="exampleEmail" className="mr-sm-2">
              Observaciones
            </Label>
            <Input
              type="textarea"
              placeholder="Observaciones"
              value={observaciones}
              required
              onChange={(e) => {
                setObservaciones(e.target.value);
              }}
            />
          </FormGroup>
          <Row>
          {validaBoton ? (
        <Button type="submit" className="btn btn-success">
        Registrar
      </Button>
      ):(
      <Button type="submit" className="btn btn-success" disabled>
        Registrar
      </Button>)}
            <div>
              <SweetAlert
                show={text}
                title="Creado con Exito"
                // text="SweetAlert in React"
                onConfirm={() => {
                  setText(false);
                }}
              />
            </div>
            <Button
              href="/FaltasColaboradores"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Row>
        </Form>
        </div>
                </div>
          
        ) : undefined}
      </div>
    </>
  );
}

export default FaltasColaboradoresCreate;

import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import ClientesInfo from "./ClientesInfo";
import { TableHeader, Pagination, Search } from "../../../DataTable"
import useFullPageLoader from "../../../hooks/useFullPageLoader"
import { v4 as uuidv4 } from "uuid";

function ListadoClientes() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_GRUPOS = process.env.REACT_APP_URL_GRUPOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_CUENTAS_CLIENTES = process.env.REACT_APP_URL_CUENTAS_CLIENTES;

  const [clientes, setClientes] = useState([]);
  const [clienteEdit, setClienteEdit] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [selectedGrupo, setSelectedGrupo] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");
  const [idEditCliente, setIdEditCliente] = useState("");
  const [selectedGrupoEdit, setSelectedGrupoEdit] = useState("");
  const [nombre_comercial, setNombreComercial] = useState("");
  const [razon_social, setRazonSocial] = useState("");
  const [RFC, setRFC] = useState("");
  const [calle, setCalle] = useState("");
  const [numero_ext, setNumeroExt] = useState("");
  const [numero_int, setNumeroInt] = useState("");
  const [delegacion, setDelegacion] = useState("");
  const [estado, setEstado] = useState("");
  const [pais, setPais] = useState("");
  const [cp, setCP] = useState("");
  const [colonia, setColonia] = useState("");
  const [codigo, setCodigo] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [telefono, setTelefono] = useState("");
  const [contactoCliente, setContactoCliente] = useState("");
  const [email, setEmail] = useState("");
  const [emailViejo, setEmailViejo] = useState("");
  const [idInfo, setIdInfo] = useState("");
  const [plazo, setPlazo] = useState("");
  const [selectedVendedor, setSelectedVendedor] = useState("");
  const [selectedVendedorName, setSelectedVendedorName] = useState("");
  // const [selectedCobradorEdit, setSelectedCobradorEdit] = useState("");
  // const [selectedCobradorName, setSelectedCobradorName] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  // const [fecha_visita, setFechaVisita] = useState("");
  const [ultimo_pago, setUltimoPago] = useState("");
  const [forma_pago, setFormaPago] = useState("");
  const [selected_grupo_name, setSelectedGrupoName] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
    const [activo, setActivo] = useState('');
    const [vendedor, setVendedor] = useState('');
    // const [cobrador, setCobrador] = useState('');
    const [grupo, setGrupo] = useState('');
    const [selectedVendedorFiltro, setSelectedVendedorFiltro] = useState("");
    const [regFiscal, setRegFiscal] = useState("");
    const [usoCFDI, setusoCFDI] = useState("");
    const [formaPagoSAT, setFormaPagoSAT] = useState("");
    const [condicionesPagoSAT, setCondicionesPagoSAT] = useState("");
    const [metodoPagoSAT, setMetodoPagoSAT] = useState("");

    const [cuentas, setCuentas] = useState([]);
    const [idClienteCuenta, setIdClienteCuenta] = useState("");
    const [modalCuenta, setModalCuenta] = useState(false);
    const toggleCuenta = () => setModalCuenta(!modalCuenta);

    const [inputFieldsCuentas, setInputFieldsCuentas] = useState([
      {
        id: uuidv4(),
        banco: "",
        cuenta:"",
        rfc:"",
      },
    ]);


    //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50

  useEffect(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
    let arrayTabla = allClientes
    .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
    .map((a) => {
      return {
        _id:a._id,
        activo: a.is_active,
        telefono: a.telefono,
        email: a.email,
        calle: a.calle,
        numero_ext: a.numero_ext,
        numero_int: a.numero_int,
        pais: a.pais,
        estado: a.estado,
        ciudad: a.ciudad,
        delegacion: a.delegacion,
        colonia: a.colonia,
        codigo: a.codigo,
        cp: a.cp,
        nombre_comercial: a.nombre_comercial,
        razon_social: a.razon_social,
        RFC: a.RFC,
        grupo: a.grupos[0].name,
        idGrupo: a.grupos[0]._id,
        vendedor: a.contacto[0].nombre + " " + a.contacto[0].apellido,
        idVendedor: a.contacto[0]._id,
        observaciones: a.observaciones,
        ultimo_pago: a.ultimo_pago,
        forma_pago: a.forma_pago,
        plazo: a.plazo,
        contactoCliente: a.contactoCliente,
        regFiscal: a.regFiscal,
        usoCFDI: a.usoCFDI,
        formaPagoSAT: a.formaPagoSAT,
        condicionesPagoSAT: a.condicionesPagoSAT,
        metodoPagoSAT: a.metodoPagoSAT,
        fiscal: a.fiscal,
      };
    }).filter(function (el) {
      return el != null;
    });

    let dataFinal = Object.values(arrayTabla);

    setClientes(allClientes);
    setComments(dataFinal)
    //

      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GRUPOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGrupos = response.data;
        setGrupos(allGrupos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ListaClientes = clientes.sort((a, b) =>
    a.nombre_comercial > b.nombre_comercial ? 1 : -1
    
  );

  function PDFTabla() {
    const data = ListaClientes.map((a) => {
      if (
        (grupo == 0 || grupo == a.grupos[0]._id) &&
        // (cobrador == 0 ||
        //   cobrador == a.colaboradores[0]._id) &&
          (vendedor == 0 ||
            vendedor == a.contacto[0]._id) &&
          (activo == 0 ||
            activo == a.is_active)
      ) {
        return [
          a.nombre_comercial,
          a.telefono,
          a.email,
          a.grupos[0].name,
          [a.contacto[0].nombre + " " + a.contacto[0].apellido],
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Clientes`, 20, 40);
    doc.autoTable({
      head: [["Nombre", "Telefono", "Mail", "Zona", "Vendedor"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Clientes.pdf`);
  }

  function excel() {
    const dataExcel = ListaClientes.map((a) => {
      if (
        (grupo == 0 || grupo == a.grupos[0]._id) &&
        // (cobrador == 0 ||
        //   cobrador == a.colaboradores[0]._id) &&
          (vendedor == 0 ||
            vendedor == a.contacto[0]._id) &&
          (activo == 0 ||
            activo == a.is_active)
      ) {
        return {
          Nombre: a.nombre_comercial,
          Telefono: a.telefono,
          Mail: a.email,
          Zona: a.grupos[0].name,
          Vendedor: [
            a.contacto[0].nombre + " " + a.contacto[0].apellido,
          ],
          // Cobrador: [
          //   a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
          // ],
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoClientes";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoClientes",
        sheetFilter: ["Nombre", "Telefono", "Mail", "Zona", "Vendedor"],
        sheetHeader: ["Nombre", "Telefono", "Mail", "Zona", "Vendedor"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaClientes.map((a) => {
      if (
        (grupo == 0 || grupo == a.grupos[0]._id) &&
        // (cobrador == 0 ||
        //   cobrador == a.colaboradores[0]._id) &&
          (vendedor == 0 ||
            vendedor == a.contacto[0]._id) &&
          (activo == 0 ||
            activo == a.is_active)
      ) {
        return [
          a.nombre_comercial,
          a.telefono,
          a.email,
          a.grupos[0].name,
          [a.contacto[0].nombre + " " + a.contacto[0].apellido],
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Clientes`, 20, 40);
    doc.autoTable({
      head: [["Nombre", "Telefono", "Mail", "Zona", "Vendedor"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Clientes",
          email: mailTo,
          fileName: "ListadoClientes.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Clientes.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(idEdit,
    telefono, contactoCliente, email, calle, numero_ext, 
    numero_int, pais, estado, ciudad, delegacion, colonia, cp, nombre_comercial, 
    razon_social, RFC, gruposName, gruposId,
    vendedorNombre, vendedorId, observaciones, ultimo_pago, forma_pago, codigo,
    plazo, usoCFDI, regFiscal, 
    formaPagoSAT,
    condicionesPagoSAT,
    metodoPagoSAT) {
        setTelefono(telefono);
        setContactoCliente(contactoCliente)
        setEmail(email);
        setEmailViejo(email);
        setCalle(calle);
        setNumeroExt(numero_ext);
        setNumeroInt(numero_int);
        setPais(pais);
        setEstado(estado);
        setCiudad(ciudad);
        setDelegacion(delegacion);
        setColonia(colonia);
        setCP(cp);
        setNombreComercial(nombre_comercial);
        setRazonSocial(razon_social);
        setRFC(RFC);
        setSelectedGrupoName(gruposName);
        setSelectedGrupoEdit(gruposId);
        setSelectedVendedorName(vendedorNombre);
        setSelectedVendedor(vendedorId);
        setObservaciones(observaciones);
        setUltimoPago(ultimo_pago);
        setFormaPago(forma_pago);
        setCodigo(codigo)
        setIdEditCliente(idEdit);
        setPlazo(plazo)
        setusoCFDI(usoCFDI)
        setRegFiscal(regFiscal)
        setFormaPagoSAT(formaPagoSAT);
        setCondicionesPagoSAT(condicionesPagoSAT);
        setMetodoPagoSAT(metodoPagoSAT);
        toggleEdit();
  }

  function Info(id) {
    setIdInfo(id);
    toggle();
  }

  function editClientes(event) {
    event.preventDefault();
    const URL_CLIENTES_EDIT = `${process.env.REACT_APP_URL_CLIENTES}/${idEditCliente}`;
        axios
          .all([
            axios.patch(
              URL_CLIENTES_EDIT,
              {
                nombre_comercial,
                razon_social,
                RFC,
                calle,
                numero_ext,
                numero_int,
                delegacion,
                estado,
                pais,
                cp,
                colonia,
                ciudad,
                telefono,
                contactoCliente,
                email,
                grupos: selectedGrupoEdit,
                contacto: selectedVendedor,
                observaciones,
                ultimo_pago,
                forma_pago,
                codigo,
                plazo,
                usoCFDI,
                regFiscal,
                formaPagoSAT,
                condicionesPagoSAT,
                metodoPagoSAT,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            ),
          ])
          .then(() => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Cliente",
                detalle: `${nombre_comercial}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
            toggleEdit();
            Swal.fire("Good job!", "Actualizado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
  }


  const headers = [
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Nombre Comercial", field: "nombre_comercial", sortable: true },
    { name: "Contacto", field: "contactoCliente", sortable: false },
    { name: "Telefono", field: "telefono", sortable: false },
    { name: "Zona", field: "grupo", sortable: true },
    { name: "Vendedor", field: "vendedor", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
];

  const commentsData = useMemo(() => {
    let computedComments = comments

    if (search) {
        computedComments = computedComments.filter(
            comment =>
                comment.nombre_comercial.toLowerCase().includes(search.toLowerCase()) ||
                // comment.apellido.toLowerCase().includes(search.toLowerCase()) ||
                comment.vendedor.toLowerCase().includes(search.toLowerCase()) ||
                comment.grupo.toLowerCase().includes(search.toLowerCase()) ||
                comment.codigo.toString().includes(search)  ||
                comment.vendedor.toLowerCase().includes(search.toLowerCase())
        );
    }
    if(grupo){
      computedComments = computedComments.filter((e)=>
      e.idGrupo.includes(grupo)
      ) 
    } 
    if(vendedor){
      computedComments = computedComments.filter((e)=>
      e.idVendedor.includes(vendedor)
      ) 
    } 
    // if(cobrador){
    //   computedComments = computedComments.filter((e)=>
    //   e.idCobrador.includes(cobrador)
    //   ) 
    // } 
    if(activo){
      computedComments = computedComments.filter((e)=>
      e.activo.includes(activo)
      ) 
    }


    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "codigo" 
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "codigo" )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "codigo")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [comments, currentPage, search, sorting, vendedor, grupo, activo]);



function cue(id, nombre_comercial) {
  setIdClienteCuenta(id);
  setNombreComercial(nombre_comercial);
  axios
    .get(`${URL_CUENTAS_CLIENTES}/cliente/${id}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allCuentas = res.data;
      setCuentas(allCuentas);
    })
    .catch((err) => {
      console.log(err);
    });
  toggleCuenta();
}

const handleChangeInputCuentasExistentes = (id, event) => {
  const newCuentas = cuentas.map((i) => {
    if (id === i._id) {
      i[event.target.name] = event.target.value;
    }
    return i;
  });
  setCuentas(newCuentas);
};

const handleChangeInputCuentas = (id, event) => {
  const newInputFieldsCuentas = inputFieldsCuentas.map((i) => {
    if (id === i.id) {
      i[event.target.name] = event.target.value;
    }
    return i;
  });
  setInputFieldsCuentas(newInputFieldsCuentas);
};

function saveCuenta() {
  let totalCuen = cuentas.length + inputFieldsCuentas.length;
  cuentas.map((a) => {
    if (a.cuenta != "") {
      axios
        .patch(
          `${URL_CUENTAS_CLIENTES}/${a._id}`,
          {
            cuenta: a.cuenta,
            banco: a.banco,
            rfc: a.rfc,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          totalCuen = totalCuen - 1;
          if (totalCuen == 0) {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Cuentas Clientes",
                detalle: `Cliente ${idClienteCuenta} / Cuenta ${a.cuenta}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem(
                    "app_token"
                  )}`,
                },
              }
            );
            Swal.fire("Good job!", "Creado con exito", `success`);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } else {
      totalCuen = totalCuen - 1
      if (totalCuen == 0) {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Cuenta Clientes",
            detalle: `Cliente ${idClienteCuenta} / Cuenta ${a.cuenta}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem(
                "app_token"
              )}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", `success`);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  });

  // agregar inputFields
  inputFieldsCuentas.map((a) => {
    if (a.cuenta != "") {
      axios
        .post(
          `${URL_CUENTAS_CLIENTES}`,
          {
            clientes: idClienteCuenta,
            cuenta: a.cuenta,
            banco: a.banco,
            rfc: a.rfc,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          totalCuen = totalCuen - 1;
          if (totalCuen == 0) {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Cuenta Clientes",
                detalle: `Cliente ${idClienteCuenta} / Cuenta ${a.cuenta}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem(
                    "app_token"
                  )}`,
                },
              }
            );
            Swal.fire("Good job!", "Creado con exito", `success`);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } else {
      totalCuen = totalCuen - 1
      if (totalCuen == 0) {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Cuenta Clientes",
            detalle: `Cliente ${idClienteCuenta} / Cuenta ${a.cuenta}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem(
                "app_token"
              )}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", `success`);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  });
}

const handleAddFieldsCuentas = () => {
  setInputFieldsCuentas([
    ...inputFieldsCuentas,
    {
      id: uuidv4(),
      banco: "",
      cuenta:"",
      rfc:"",
    },
  ]);
};

const handleRemoveFieldsCuentas = (id) => {
  const values = [...inputFieldsCuentas];
  values.splice(
    values.findIndex((value) => value.id === id),
    1
  );
  setInputFieldsCuentas(values);
};

function altaCuenta(id) {
  axios
    .patch(
      `${URL_CUENTAS_CLIENTES}/${id}`,
      {
        is_active: "Si"
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem(
            "app_token"
          )}`,
        },
      }
    )
    .then((data) => {
          Swal.fire("Good job!", "Creado con exito", "success");

          const newCuentas = cuentas.map((i) => {
            if (id === i._id) {
              i.is_active = "Si";
            }
            return i;
          });
          setCuentas(newCuentas);
       
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        // footer: `${error.response.data}`,
      });
      console.log(error);
    });
}

function bajaCuenta(id) {
axios
.patch(
`${URL_CUENTAS_CLIENTES}/${id}`,
{
is_active: "No"
},
{
headers: {
  Authorization: `Bearer: ${localStorage.getItem(
    "app_token"
  )}`,
},
}
)
.then((data) => {
  Swal.fire("Good job!", "Creado con exito", "success");
  const newCuentas = cuentas.map((i) => {
    if (id === i._id) {
      i.is_active = "No";
    }
    return i;
  });
  setCuentas(newCuentas);
})
.catch((error) => {
Swal.fire({
icon: "error",
title: "Oops...",
text: "Something went wrong!",
// footer: `${error.response.data}`,
});
console.log(error);
});
}

function altaFiscal(id) {
  axios
    .patch(
      `${URL_CLIENTES}/${id}`,
      {
        fiscal: "Si"
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem(
            "app_token"
          )}`,
        },
      }
    )
    .then((data) => {
          Swal.fire("Good job!", "Creado con exito", "success");

          const newComments = comments.map((i) => {
            if (id === i._id) {
              i.fiscal = "Si";
            }
            return i;
          });
          setComments(newComments);
       
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        // footer: `${error.response.data}`,
      });
      console.log(error);
    });
}

function bajaFiscal(id) {
axios
.patch(
`${URL_CLIENTES}/${id}`,
{
fiscal: "No"
},
{
headers: {
  Authorization: `Bearer: ${localStorage.getItem(
    "app_token"
  )}`,
},
}
)
.then((data) => {
  Swal.fire("Good job!", "Creado con exito", "success");
  const newComments = comments.map((i) => {
    if (id === i._id) {
      i.fiscal = "No";
    }
    return i;
  });
  setComments(newComments);
})
.catch((error) => {
Swal.fire({
icon: "error",
title: "Oops...",
text: "Something went wrong!",
// footer: `${error.response.data}`,
});
console.log(error);
});
}


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_clientes ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.clientes_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ClientesCreate"
                >
                  Nuevo Cliente
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Cliente
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Clientes</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Clientes</h3>
          <div className="row">
                        <div className="col-md-8">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-4 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
          <Row>
            <Col md={2}>
              <Label>Zonas</Label>
              <Input
                bsSize="sm"
                type="select"
                value={grupo}
                onChange={(e) => {
                  setGrupo(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="">Selecciona</option>
                {grupos
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Vendedor</Label>
              <Input
                bsSize="sm"
                type="select"
                value={vendedor}
                onChange={(e) => {
                  setVendedor(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="0">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    // if (a.cobrador == "Si") {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    // }
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
            <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
            <tbody>
              {commentsData.map((p) => {
                {
                  return (
                    <tr>
                      <td>{p.codigo}</td>
                      <td>
                        {p.nombre_comercial}
                      </td>
                      <td>{p.contactoCliente}</td>
                      <td>{p.telefono}</td>
                      <td>{p.grupo}</td>
                      {/* <td>
                        {p.cobrador}
                      </td> */}
                      <td>
                        {p.vendedor}
                      </td>
                      <td>
                        {user.clientes_create ? (
                          <div>
                            <Button
                              color="dany"
                              size="sm"
                              onClick={(e) => Info(p._id)}
                              id="Detalle"
                            >
                              <i class="fas fa-file-alt"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) => jalaInfo(p._id, 
                                p.telefono, p.contactoCliente, p.email, p.calle, p.numero_ext, p.numero_int,
                                p.pais, p.estado, p.ciudad, p.delegacion, p.colonia, p.cp, p.nombre_comercial, p.razon_social, p.RFC, p.grupo,
                                p.idGrupo, p.vendedor,
                                p.idVendedor, p.observaciones, p.ultimo_pago, p.forma_pago, p.codigo, p.plazo, p.usoCFDI, p.regFiscal, 
                                p.formaPagoSAT,
                                p.condicionesPagoSAT,
                                p.metodoPagoSAT)}
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                            <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) =>
                                    cue(p._id, p.nombre_comercial)
                                  }
                                >
                                  <i class="fas fa-dollar-sign"></i>
                                </Button>

                                {p.fiscal == "Si"?(
                            <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={() => bajaFiscal(p._id)}
                            tabindex="-1"
                          >
                            F
                          </Button>
                          ):(
                            <Button
                            size="sm"
                            className="btn"
                            color="primary"
                            onClick={() => altaFiscal(p._id)}
                            tabindex="-1"
                          >
                            F
                          </Button>
                          )}
                            <Baja
                              idStatus={p._id}
                              is_active={p.activo}
                              URL_BAJA={process.env.REACT_APP_URL_CLIENTES}
                            />
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4>Informacion del Cliente</h4>{" "}
        </ModalHeader>
        <ModalBody>
          <ClientesInfo idCliente={idInfo} />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>


      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            Editar cliente {nombre_comercial}
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
              {/* <Col md={3}>
                <Label>Nombre</Label>
                <Input
                  type="text"
                  placeholder="Nombre"
                  value={nombre}
                  required
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Apellido</Label>
                <Input
                  type="text"
                  placeholder="Apellido"
                  value={apellido}
                  required
                  onChange={(e) => {
                    setApellido(e.target.value);
                  }}
                />
              </Col> */}
               {/* <Col md={3}>
                <Label>Codigo</Label>
                <Input
                  type="text"
                  placeholder="Codigo"
                  value={codigo}
                  required
                  onChange={(e) => {
                    setCodigo(e.target.value);
                  }}
                />
              </Col> */}
              <Col md={3}>
                <Label>Nombre Comercial</Label>
                <Input
                  type="text"
                  placeholder="Nombre Comercial"
                  value={nombre_comercial}
                  required
                  onChange={(e) => {
                    setNombreComercial(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Razon Social</Label>
                <Input
                  type="text"
                  placeholder="Razon Social"
                  value={razon_social}
                  required
                  onChange={(e) => {
                    setRazonSocial(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>RFC</Label>
                <Input
                  type="text"
                  placeholder="RFC"
                  value={RFC}
                  required
                  onChange={(e) => {
                    setRFC(e.target.value);
                  }}
                />
              </Col>
              
              
            </Row>

            <Row>
              <Col md={8}>
                <Label>Calle</Label>
                <Input
                  type="text"
                  placeholder="Calle"
                  value={calle}
                  required
                  onChange={(e) => {
                    setCalle(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Ext</Label>
                <Input
                  type="text"
                  placeholder="No. Ext"
                  value={numero_ext}
                  required
                  onChange={(e) => {
                    setNumeroExt(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Int</Label>
                <Input
                  type="text"
                  placeholder="No. Int"
                  value={numero_int}
                  required
                  onChange={(e) => {
                    setNumeroInt(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Label>Pais</Label>
                <Input
                  type="text"
                  placeholder="Pais"
                  value={pais}
                  required
                  onChange={(e) => {
                    setPais(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Estado</Label>
                <Input
                  type="text"
                  placeholder="Estado"
                  value={estado}
                  required
                  onChange={(e) => {
                    setEstado(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Ciudad</Label>
                <Input
                  type="text"
                  placeholder="Ciudad"
                  value={ciudad}
                  required
                  onChange={(e) => {
                    setCiudad(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Label>Delegacion</Label>
                <Input
                  type="text"
                  placeholder="Delegacion o Municipio"
                  value={delegacion}
                  required
                  onChange={(e) => {
                    setDelegacion(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Colonia</Label>
                <Input
                  type="text"
                  placeholder="Colonia"
                  value={colonia}
                  required
                  onChange={(e) => {
                    setColonia(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>CP</Label>
                <Input
                  type="text"
                  placeholder="CP"
                  value={cp}
                  required
                  onChange={(e) => {
                    setCP(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
            <Col md={3}>
                <Label>Contacto Cliente</Label>
                <Input
                  type="text"
                  placeholder="Contacto Cliente"
                  value={contactoCliente}
                  required
                  onChange={(e) => {
                    setContactoCliente(e.target.value);
                  }}
                />
              </Col>
            <Col md={3}>
                <Label>Telefono</Label>
                <Input
                  type="text"
                  placeholder="Telefono"
                  value={telefono}
                  required
                  onChange={(e) => {
                    setTelefono(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Email</Label>
                <Input
                  type="email"
                  placeholder="Email"
                  value={email}
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Col>
              
             
            </Row>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Zona</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedGrupoEdit}
                  required
                  onChange={(e) => {
                    setSelectedGrupoEdit(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Zona</option>
                  {grupos
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>

              <Col md={3}>
                <Label className="mr-sm-2">Vendedor</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedVendedor}
                  required
                  onChange={(e) => {
                    setSelectedVendedor(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    })}
                </Input>
              </Col>
               <Col md={3}>
                <Label>Forma de Pago</Label>
                <Input
                  type="select"
                  placeholder="Forma de Pago"
                  value={forma_pago}
                  required
                  onChange={(e) => {
                    setFormaPago(e.target.value);
                  }}
                >
                  <option value="0">Forma de Pago</option>
                  <option value="Efectivo">Efectivo</option>
                  <option value="Deposito">Deposito</option>
                  <option value="Cheque">Cheque</option>
                  <option value="PorDefinir">Por Definir</option>
                </Input>
              </Col>
              <Col md={3}>
                <Label>Plazo</Label>
                <Input
                  type="select"
                  placeholder="Plazo"
                  value={plazo}
                  required
                  onChange={(e) => {
                    setPlazo(e.target.value);
                  }}
                >
                  <option value="">Plazo</option>
                  <option value="0">0</option>
                  <option value="30">30</option>
                  <option value="60">60</option>
                  <option value="90">90</option>
                  <option value="120">120</option>
                  <option value="150">150</option>
                </Input>
              </Col>
            </Row>
            
            <Row>
            <Col md={3}>
                <Label>Regimen Fiscal</Label>
                <Input
                  type="select"
                  placeholder="RegFiscal"
                  value={regFiscal}
                  required
                  onChange={(e) => {
                    setRegFiscal(e.target.value);
                  }}
                >
                  <option value="">Reg Fiscal</option>
                  <option value="0">0</option>
                  <option value="601">601 General de Ley Personas Morales</option>
                  <option value="603">603 Personas Morales con Fines no Lucrativos</option>
                  <option value="605">605 Sueldos y Salarios e Ingresos Asimilados al Salario</option>
                  <option value="608">608 Demas Ingresos</option>
                  <option value="612">612 Personas Físicas con Actividades Empresariales y Profesionales</option>
                  <option value="616">616 Sin Obligacion Fiscal</option>
                  <option value="621">621 Incorporacion Fiscal</option>
                  <option value="624">624 Régimen de los Coordinados</option>
                  <option value="625">625 Reg. de las act. emp. cpn ingresos a traves de plat. tec.</option>
                  <option value="626">626 Regimen simplificado de Confianza</option>
                </Input>
              </Col>

              <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Uso de CDFI
                </Label>
                <Input
                  type="select"
                  placeholder="Uso de CDFI"
                  value={usoCFDI}
                  required
                  onChange={(e) => {
                    setusoCFDI(e.target.value);
                  }}
                >
                  <option value="">Uso de CDFI</option>
                  <option value="G01">G01 Adquisición de mercancías</option>
                  <option value="G03">G03 Gastos en general</option>
                  </Input>
              </Col>
              <Col md={3}>
                <Label>Forma Pago Fiscal</Label>
                <Input
                  type="select"
                  value={formaPagoSAT}
                  required
                  onChange={(e) => {
                    setFormaPagoSAT(e.target.value);
                  }}
                >

              <option value="01"> 01 Efectivo</option>
              <option value="02"> 02 Cheque nominativo</option>
              <option value="03"> 03 Transferencia electrónica de fondos</option>
              <option value="04"> 04 Tarjeta de crédito</option>
              <option value="05"> 05 Monedero electrónico</option>
              <option value="06"> 06 Dinero electrónico</option>
              <option value="08"> 08 Vales de despensa</option>
              <option value="12"> 12 Dación en pago</option>
              <option value="13"> 13 Pago por subrogación</option>
              <option value="14"> 14 Pago por consignación</option>
              <option value="15"> 15 Condonación</option>
              <option value="17"> 17 Compensación</option>
              <option value="23"> 23 Novación</option>
              <option value="24"> 24 Confusión</option>
              <option value="25"> 25 Remisión de deuda</option>
              <option value="26"> 26 Prescripción o caducidad</option>
              <option value="27"> 27 A satisfacción del acreedor</option>
              <option value="28"> 28 Tarjeta de débito</option>
              <option value="29"> 29 Tarjeta de servicios</option>
              <option value="30"> 30 Aplicación de anticipos</option>
              <option value="99"> 99 Por definir</option>
                </Input>
              </Col>

              <Col md={3}>
                <Label>Metodo Pago Fiscal</Label>
                <Input
                  type="select"
                  value={metodoPagoSAT}
                  required
                  onChange={(e) => {
                    setMetodoPagoSAT(e.target.value);
                  }}
                >

              <option value="PUE">PUE (Pago de Una Sola Exhibición)</option>
              <option value="PPD">PPD (Pago en Parcialidades o Diferido)</option>
                </Input>
              </Col>

              <Col md={3}>
                <Label>Condiciones de Pago Fiscal</Label>
                <Input
                  type="text"
                  value={condicionesPagoSAT}
                  required
                  onChange={(e) => {
                    setCondicionesPagoSAT(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
             
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editClientes}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      
      <Modal size="xl" isOpen={modalCuenta} toggle={toggleCuenta}>
        <ModalHeader toggle={toggleCuenta}>
          <h4>Cuentas {nombre_comercial}</h4>
        </ModalHeader>
        <ModalBody>
        <Table striped borderless>
                  <thead>
                    <tr>
                      <th>Banco</th>
                      <th>RFC</th>
                      <th>Cuentas</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cuentas.map((a) => (
                      <tr>
                         <td>
                          <Input
                            type="text"
                            name="banco"
                            value={a.banco}
                            key={a._id}
                            onChange={(e) => {
                              handleChangeInputCuentasExistentes(a._id, e);
                            }}
                          />
                        </td>
                         <td>
                          <Input
                            type="text"
                            name="rfc"
                            value={a.rfc}
                            key={a._id}
                            onChange={(e) => {
                              handleChangeInputCuentasExistentes(a._id, e);
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            name="cuenta"
                            type="text"
                            value={a.cuenta}
                            required
                            // maxLength="4" // Limit to 4 digits
                            // pattern="\d{1,4}" 
                            onChange={(event) => {
                              handleChangeInputCuentasExistentes(a._id, event);
                            }}
                          />
                        </td>
                        <td>
                          {a.is_active == "Si"?(
                            <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={() => bajaCuenta(a._id)}
                            tabindex="-1"
                          >
                            <i class="far fa-times-circle"></i>
                          </Button>
                          ):(
                            <Button
                            size="sm"
                            className="btn"
                            color="primary"
                            onClick={() => altaCuenta(a._id)}
                            tabindex="-1"
                          >
                            <i class="far fa-check-circle"></i>
                          </Button>
                          )}
                         
                        </td>
                      </tr>
                      // </div>
                    ))}

              {inputFieldsCuentas.map((a) => (
                      <tr>
                         <td>
                          <Input
                            type="text"
                            name="banco"
                            value={a.banco}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputCuentas(a.id, e);
                            }}
                          />
                        </td>
                         <td>
                          <Input
                            type="text"
                            name="rfc"
                            value={a.rfc}
                            key={a._id}
                            onChange={(e) => {
                              handleChangeInputCuentas(a.id, e);
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            name="cuenta"
                            type="text"
                            value={a.cuenta}
                            required
                            // maxLength="4" // Limit to 4 digits
                            // pattern="\d{1,4}" 
                            onChange={(event) => {
                              handleChangeInputCuentas(a.id, event);
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            disabled={a.length === 1}
                            onClick={() => handleRemoveFieldsCuentas(a.id)}
                            tabindex="-1"
                          >
                            <i class="fas fa-minus"></i>
                          </Button>
                          <Button
                            size="sm"
                            className="btn"
                            color="info"
                            onClick={handleAddFieldsCuentas}
                            tabindex="-1"
                          >
                            <i class="fas fa-plus"></i>
                          </Button>
                        </td>
                      </tr>
                      // </div>
                    ))}

                  </tbody>
                  <br />
                </Table>
                <br />
                <Row>
                <Button className="btn btn-success" onClick={saveCuenta}>
                Registrar
              </Button>
                </Row>

        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoClientes;

import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesNominas() {
  const { user } = useContext(AuthContext);
    return (
        <>
           <Header />
        <br />
        <br />
        <div className="container">
        {user.menu_nominas ? (
          <div>
        <Row>
          <Col md={6} align="center">
          {user.menu_empleados ? (
            <Button href="/Empleados" className="botonesMenu" color="success">
            <i class="fas fa-address-card fa-7x"></i>
              <br />
              <br />
              Empleados
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-address-card fa-7x"></i>
            <br />
            <br />
            Empleados
          </Button>}
            </Col>
            <Col md={6} align="center">
            {user.menu_puestos ? (
            <Button href="/Puestos" className="botonesMenu" color="success">
            <i class="fas fa-address-book fa-7x"></i>
              <br />
              <br />
              Puestos
            </Button>
            ):<Button disabled className="botonesMenu" color="success">
            <i class="fas fa-address-book fa-7x"></i>
              <br />
              <br />
              Puestos
            </Button>}
          </Col>
          {/* <Col md={4} align="center">
            {user.nominas_listado ? (
            <Button href="/Nominas" className="botonesMenu" color="success">
            <i class="fas fa-briefcase fa-7x"></i>
              <br />
              <br />
              Nominas
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-briefcase fa-7x"></i>
              <br />
              <br />
              Nominas
            </Button> }
          </Col> */}
        </Row>
        <br />
        <Row>
          {/* <Col md={4} align="center">
          {user.menu_empleados ? (
          <Button href="/FaltasColaboradores" className="botonesMenu" color="success">
            <i class="fas fa-calendar-times fa-7x"></i>
              <br />
              <br />
              Faltas Empleados
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-calendar-times fa-7x"></i>
              <br />
              <br />
              Faltas Empleados
            </Button> }
          </Col> */}
        </Row>
        </div>
        ) : undefined}
      </div>
       
        </>
    )
}

export default BotonesNominas

import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";

function PeriodosAdminCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_PERIODOS_GASTOS = process.env.REACT_APP_URL_PERIODOS_GASTOS;
  const URL_CICLOS = process.env.REACT_APP_URL_CICLOS;
  const [name, setName] = useState("");
  const [fecha_inicio, setFechaInicio] = useState("");
  const [fecha_fin, setFechaFin] = useState("");
  const [ciclos, setCiclos] = useState([]);
  const [selectedCiclo, setSelectedCiclo] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  const clear = () => {
    setName("");
    setFechaInicio("");
    setFechaFin("");
    setSelectedCiclo();
    setValidaBoton(true);
  };

  useEffect(() => {
    axios
      .get(URL_CICLOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCiclos = res.data;
        setCiclos(allCiclos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const savePeriodosAdmin = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    axios
      .post(
        URL_PERIODOS_GASTOS,
        {
          name,
          fecha_inicio,
          fecha_fin,
          ciclos: selectedCiclo,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Periodo",
            detalle: `Nombre: ${name} / Ciclo: ${selectedCiclo}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        clear();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: "<a href>Why do I have this issue?</a>",
        });
        console.log(error);
      });
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_periodos_admin ? (
        <div className="card container col-sm-4">
          <h3 align="center">Nuevo Periodo</h3>
          <Form onSubmit={savePeriodosAdmin}>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
                <Col md={6}>
                  <Label for="exampleEmail" className="mr-sm-2">
                    Periodo
                  </Label>
                  <Input
                    type="text"
                    name="email"
                    id="exampleEmail"
                    placeholder="Nombre del Periodo"
                    value={name}
                    required
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Col>

                <Col md={6}>
                  <Label className="mr-sm-2">Ciclo</Label>
                  <Input
                    type="select"
                    value={selectedCiclo}
                    required
                    onChange={(e) => {
                      setSelectedCiclo(e.target.value);
                    }}
                  >
                    <option value="0">Selecciona un Ciclo</option>
                    {ciclos
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((c) => {
                        return <option value={c._id}>{c.name}</option>;
                      })}
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Label>Fecha Inicio</Label>
                  <Input
                    type="date"
                    value={fecha_inicio}
                    required
                    onChange={(e) => {
                      setFechaInicio(e.target.value);
                    }}
                  />
                </Col>
                <Col md={6}>
                  <Label>Fecha Fin</Label>
                  <Input
                    type="date"
                    value={fecha_fin}
                    required
                    onChange={(e) => {
                      setFechaFin(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
            <br />
            <Col>
              <Row>
                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                    Guardar
                  </Button>
                ) : (
                  <Button type="submit" className="btn btn-success" disabled>
                    Guardar
                  </Button>
                )}
                <div>
                  <SweetAlert
                    show={text}
                    title="Creado con Exito"
                    // text="SweetAlert in React"
                    onConfirm={() => {
                      setText(false);
                      window.location.href = "/PeriodosAdmin";
                    }}
                  />
                </div>
                <Button
                  href="/PeriodosAdmin"
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Row>
            </Col>
          </Form>
        </div>
      ) : undefined}
      
    </>
  );
}

export default PeriodosAdminCreate;

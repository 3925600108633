import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesFiscal() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_fiscal ?(
      <div className="container">
        <br />
        <br />
        <Row >
        <Col md={4} align="center">
          {user.facturasFiscal  ?(
            <Button href="/ListadoFacturas" className="botonesMenu" color="success">
              <i class="fas fa-file-invoice-dollar fa-7x"></i>
              <br />
              <br />
              Facturas
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-file-invoice-dollar fa-7x"></i>
          <br />
          <br />
          Facturas
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.compPagosFiscal ?(
            <Button href="/ListadoComplementosPago" className="botonesMenu" color="success">
              <i class="fas fa-money-bill-wave fa-7x"></i>
              <br />
              <br />
              Complementos Pagos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-money-bill-wave fa-7x"></i>
          <br />
          <br />
          Complementos Pagos
        </Button> }
          </Col>    

          <Col md={4} align="center">
          {user.compPagosFiscal ?(
            <Button href="/ListadoPagosFacturas" className="botonesMenu" color="success">
              <i class="fas fa-money-bill-wave fa-7x"></i>
              <br />
              <br />
              Pagos a Facturas
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-money-bill-wave fa-7x"></i>
          <br />
          <br />
          Pagos a Facturas
        </Button> }
          </Col>         

          </Row>
          <br />
          <Row>
          <Col md={4} align="center">
          {user.notasCreditoFiscal ?(
            <Button href="/ListadoNotasCredito" className="botonesMenu" color="success">
              <i class="fas fa-money-check-alt fa-7x"></i>
              <br />
              <br />
              Notas de Credito
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-money-check-alt fa-7x"></i>
          <br />
          <br />
          Notas de Credito
        </Button> }
          </Col>
           <Col md={4} align="center">
          {user.devolucionesFiscal ?(
            <Button href="/ListadoDevolucionesFiscal" className="botonesMenu" color="success">
              <i class="fas fa-undo-alt fa-7x"></i>
              <br />
              <br />
              Devoluciones
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-undo-alt fa-7x"></i>
          <br />
          <br />
          Devoluciones
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menu_fiscal ?(
            <Button href="/RegistrarEmpresas" className="botonesMenu" color="success">
              <i class="fas fa-file-alt fa-7x"></i>
              <br />
              <br />
              Registrar FIEL
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-file-alt fa-7x"></i>
          <br />
          <br />
          Registrar FIEL
        </Button> }
          </Col>
         
          </Row>
          <br />
          <Row>
          <Col md={4} align="center">
          {user.menu_fiscal ?(
            <Button href="/ListadoEmpresas" className="botonesMenu" color="success">
              <i class="fas fa-file-alt fa-7x"></i>
              <br />
              <br />
              Empresas
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-file-alt fa-7x"></i>
          <br />
          <br />
          Empresas 
        </Button> }
          </Col>
          </Row>

      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesFiscal;

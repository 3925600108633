import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
  Badge
} from "reactstrap";
import moment from 'moment';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";


function TablaCompras() {
  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const { user } = useContext(AuthContext);
  const URL_COMPRAS_ACUMULADOS = process.env.REACT_APP_URL_COMPRAS_ACUMULADOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [graficaProveedor, setGraficaProveedor] = useState([]);
  const [graficaArticulo, setGraficaArticulo] = useState([]);
  const [graficaColor, setGraficaColor] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);  
  
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");

  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");

  const [colores, setColores] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [value, setValue] = useState(null);

useMemo(()=>{
  axios
  .get(`${URL_COMPRAS_ACUMULADOS}Reporte/${selectedFechaInicio}/${selectedFechaFin}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allGraficas = response.data;
    let arrayTabla = allGraficas
    .map((a) => {
      if(
        (a.compras[0].is_active == "Si") &&
        (selectedProveedor == 0 || selectedProveedor == a.pedidos[0].proveedores[0]._id) &&
        (value == null || value._id == a.articulos[0]._id) &&
        (selectedColor == 0 || selectedColor == a.colores[0]._id) &&
        (selectedColaborador == 0 || selectedColaborador == a.pedidos[0].colaboradores[0]._id)
        ){
      return {
        _id:a._id,
        idProveedor: a.ordenesCompra[0].proveedores[0]._id,
        proveedor: a.ordenesCompra[0].proveedores[0].nombre_comercial,
        idArticulo: a.articulos[0]._id,
        articulo: a.articulos[0].codigo + " " + a.articulos[0].nombre,
        idColor: a.colores[0]._id,
        color: a.colores[0].name,
        cantidad: a.cantidad
      }}
    }).filter(function (el) {
      return el != null;
    });

    let dataFinal = Object.values(arrayTabla);

    let agrupadoProveedor = dataFinal.reduce(function (groups, item) {
      const val = item["idProveedor"];
      groups[val] = groups[val] || {
        idProveedor: item.idProveedor,
        cantidad: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].idProveedor = item.idProveedor;
      groups[val].proveedor = item.proveedor;
      return groups;
    },[])

    let arrayProveedor = Object.values(agrupadoProveedor)
    let arrayProveedorSorteado = arrayProveedor.sort((a, b) => (a.cantidad < b.cantidad ? 1 : -1))

    setGraficaProveedor(arrayProveedorSorteado);

    let agrupadoArticulo = dataFinal.reduce(function (groups, item) {
      const val = item["idArticulo"];
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        cantidad: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].idArticulo = item.idArticulo;
      groups[val].articulo = item.articulo;
      return groups;
    },[])

    let arrayArticulo = Object.values(agrupadoArticulo)
    let arrayArticuloSorteado = arrayArticulo.sort((a, b) => (a.cantidad < b.cantidad ? 1 : -1))

    setGraficaArticulo(arrayArticuloSorteado);

    let agrupadoColor = dataFinal.reduce(function (groups, item) {
      const val = item["idColor"];
      groups[val] = groups[val] || {
        idColor: item.idColor,
        cantidad: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].idColor = item.idColor;
      groups[val].color = item.color;
      return groups;
    },[])

    let arrayColor = Object.values(agrupadoColor)
    let arrayColorSorteado = arrayColor.sort((a, b) => (a.cantidad < b.cantidad ? 1 : -1))

    setGraficaColor(arrayColorSorteado);
  })
  .catch((err) => {
    console.log(err);
  });

  axios
  .get(URL_PROVEEDORES, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((res) => {
    const allProveedores = res.data;
    setProveedores(allProveedores);
  })
  .catch((err) => {
    console.log(err);
  });

  axios
  .get(URL_ARTICULOS, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((res) => {
    const allArticulos = res.data;
    setArticulos(allArticulos);
  })
  .catch((err) => {
    console.log(err);
  });

  axios
  .get(URL_COLORES, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((res) => {
    const allColores = res.data;
    setColores(allColores);
  })
  .catch((err) => {
    console.log(err);
  });

  axios
  .get(URL_COLABORADORES, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((res) => {
    const allColaboradores = res.data;
    setColaboradores(allColaboradores);
  })
  .catch((err) => {
    console.log(err);
  });
  
}, [selectedFechaInicio, selectedFechaFin, selectedProveedor, selectedArticulo, selectedColor, selectedColaborador, value])

let totalProveedor = 0
let totalArticulo = 0
let totalColor = 0

const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

Toast.fire({
  icon: 'success',
  title: 'Danos unos segundos....'
})

function PDFTabla() {
  let totalProveedorPDF = 0
  let totalArticuloPDF = 0
  let totalColorPDF = 0
  const dataProveedor = graficaProveedor.map((a) => {
    totalProveedorPDF= totalProveedorPDF + a.cantidad
      return [
        a.proveedor,
        new Intl.NumberFormat("en-US").format(a.cantidad)
      ];
  });
  const dataProveedorPDF = Object.values(dataProveedor);
  const dataProveedorPDFLimpia = dataProveedorPDF.filter(function (el) {
    return el != null;
  });

  const dataArticulo = graficaArticulo.map((a) => {
    totalArticuloPDF= totalArticuloPDF + a.cantidad
    return [
      a.articulo,
      new Intl.NumberFormat("en-US").format(a.cantidad)
    ];
});
const dataArticuloPDF = Object.values(dataArticulo);
const dataArticuloPDFLimpia = dataArticuloPDF.filter(function (el) {
  return el != null;
});

const dataColor = graficaColor.map((a) => {
  totalColorPDF= totalColorPDF + a.cantidad
  return [
    a.color,
    new Intl.NumberFormat("en-US").format(a.cantidad)
  ];
});
const dataColorPDF = Object.values(dataColor);
const dataColorPDFLimpia = dataColorPDF.filter(function (el) {
return el != null;
});

  const doc = new jsPDF();
  var img = new Image();
  img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  doc.addImage(img, "png", 150, 25, 33, 10);
  var img2 = new Image();
  img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
 doc.addImage(img2, "png", 140, 20, 60, 25);
  doc.text(`Resumen de Compras`, 20, 33);
  doc.autoTable({
    head: [["Proveedor", "Cantidad"]],
    body: dataProveedorPDFLimpia,
    startY: 38,
    foot: [["Total", 
    new Intl.NumberFormat("en-US").format(totalProveedorPDF),
      ],
    ],
    showFoot: "lastPage",
  });
  doc.addPage();
  doc.addImage(img, "png", 150, 25, 33, 10);
 doc.addImage(img2, "png", 140, 20, 60, 25);
  doc.text(`Resumen de Compras`, 20, 33);
  doc.autoTable({
    head: [["Articulo", "Cantidad"]],
    body: dataArticuloPDFLimpia,
    startY: 38,
    foot: [["Total", 
    new Intl.NumberFormat("en-US").format(totalArticuloPDF),
      ],
    ],
    showFoot: "lastPage",
  });
  doc.addPage();

  doc.addImage(img, "png", 150, 25, 33, 10);
 doc.addImage(img2, "png", 140, 20, 60, 25);
  doc.text(`Resumen de Compras`, 20, 33);
  doc.autoTable({
    head: [["Color", "Cantidad"]],
    body: dataColorPDFLimpia,
    startY: 38,
    foot: [["Total", 
    new Intl.NumberFormat("en-US").format(totalColorPDF),
      ],
    ],
    showFoot: "lastPage",
  });
  doc.save(`ResumenCompras.pdf`);
}

function enviaMail() {
  let totalProveedorPDF = 0
  let totalArticuloPDF = 0
  let totalColorPDF = 0
  const dataProveedor = graficaProveedor.map((a) => {
    totalProveedorPDF= totalProveedorPDF + a.cantidad
      return [
        a.proveedor,
        new Intl.NumberFormat("en-US").format(a.cantidad)
      ];
  });
  const dataProveedorPDF = Object.values(dataProveedor);
  const dataProveedorPDFLimpia = dataProveedorPDF.filter(function (el) {
    return el != null;
  });

  const dataArticulo = graficaArticulo.map((a) => {
    totalArticuloPDF= totalArticuloPDF + a.cantidad
    return [
      a.articulo,
      new Intl.NumberFormat("en-US").format(a.cantidad)
    ];
});
const dataArticuloPDF = Object.values(dataArticulo);
const dataArticuloPDFLimpia = dataArticuloPDF.filter(function (el) {
  return el != null;
});

const dataColor = graficaColor.map((a) => {
  totalColorPDF= totalColorPDF + a.cantidad
  return [
    a.color,
    new Intl.NumberFormat("en-US").format(a.cantidad)
  ];
});
const dataColorPDF = Object.values(dataColor);
const dataColorPDFLimpia = dataColorPDF.filter(function (el) {
return el != null;
});

  const doc = new jsPDF();
  var img = new Image();
  img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  doc.addImage(img, "png", 150, 25, 33, 10);
  var img2 = new Image();
  img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
 doc.addImage(img2, "png", 140, 20, 60, 25);
  doc.text(`Resumen de Compras`, 20, 33);
  doc.autoTable({
    head: [["Proveedor", "Cantidad"]],
    body: dataProveedorPDFLimpia,
    startY: 38,
    foot: [["Total", 
    new Intl.NumberFormat("en-US").format(totalProveedorPDF),
      ],
    ],
    showFoot: "lastPage",
  });
  doc.addPage();
  doc.addImage(img, "png", 150, 25, 33, 10);
 doc.addImage(img2, "png", 140, 20, 60, 25);
  doc.text(`Resumen de Compras`, 20, 33);
  doc.autoTable({
    head: [["Articulo", "Cantidad"]],
    body: dataArticuloPDFLimpia,
    startY: 38,
    foot: [["Total", 
    new Intl.NumberFormat("en-US").format(totalArticuloPDF),
      ],
    ],
    showFoot: "lastPage",
  });
  doc.addPage();

  doc.addImage(img, "png", 150, 25, 33, 10);
 doc.addImage(img2, "png", 140, 20, 60, 25);
  doc.text(`Resumen de Compras`, 20, 33);
  doc.autoTable({
    head: [["Color", "Cantidad"]],
    body: dataColorPDFLimpia,
    startY: 38,
    foot: [["Total", 
    new Intl.NumberFormat("en-US").format(totalColorPDF),
      ],
    ],
    showFoot: "lastPage",
  });

  var att = doc.output("arraybuffer");
  var base64File = encode(att);

  // Envia el Mail
  axios
    .post(
      URL_SEND_MAIL_ATT,
      {
        subject: "Resumen de Compras",
        email: mailTo,
        fileName: "ResumenCompras.pdf",
        att: base64File,
        text:
       `<!DOCTYPE html>
       <html>
         <head>
           <style>                                
             .img-container {
                             display: block;
                             margin-left: auto;
                             margin-right: auto;
                             height: 90px;
                             width: auto;
                           }
           </style>
         </head>
         <body>
           <img class="img-container" alt="Logo" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" />              
           <h1>${process.env.REACT_APP_NOMBRE_INSTITUCION}</h1>
           <h3>Hola</h3>
           <h3>Adjunto encontraras el Resumen de Compras.</h3>
           <h4>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</h4>
           Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> 
         </body>
       </html>`
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      setModalMail(false);
      Swal.fire("Good job!", "Enviado con exito", "success");
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        // footer: `${error.response.data}`,
      });
      console.log(error);
    });
}

const options = articulos.map((option) => {
  const junta = option.nombre;
  const firstLetter = option.nombre[0].toUpperCase();
  return {
    firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
    ...option,
    junta,
  };
});

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
        <div className="card container col-12">
          
          <div className="container col-12">
            <Row>
            <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                {/* <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  // onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button> */}
              </ButtonGroup>
              </Row>
              <h3 align="center">Resumen de Compras</h3>
              <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Fecha Inicio</Label>
                <Input
                  // bsSize="sm"
                  type="date"
                  value={selectedFechaInicio}
                  required
                  onChange={(e) => {
                    setSelectedFechaInicio(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Fecha Fin</Label>
                <Input
                  // bsSize="sm"
                  type="date"
                  value={selectedFechaFin}
                  required
                  onChange={(e) => {
                    setSelectedFechaFin(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Proveedor</Label>
                <Input
                  // bsSize="sm"
                  type="select"
                  value={selectedProveedor}
                  required
                  onChange={(e) => {
                    setSelectedProveedor(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {proveedores
                    .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre_comercial}</option>;
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Articulo</Label>
                {/* <Input
                  // bsSize="sm"
                  type="select"
                  value={selectedArticulo}
                  required
                  onChange={(e) => {
                    setSelectedArticulo(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulos
                    .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.codigo} / {a.nombre}</option>;
                    })}
                </Input> */}
                 <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedArticulo) => {
                      setValue(selectedArticulo);
                    }}
                    // onBlur={selectArticulo}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.nombre}
                      </React.Fragment>
                    )}
                  />
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Color</Label>
                <Input
                  // bsSize="sm"
                  type="select"
                  value={selectedColor}
                  required
                  onChange={(e) => {
                    setSelectedColor(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colores
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Vendedor</Label>
                <Input
                  // bsSize="sm"
                  type="select"
                  value={selectedColaborador}
                  required
                  onChange={(e) => {
                    setSelectedColaborador(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre} {a.apellido}</option>;
                    })}
                </Input>
              </Col>
            </Row>
            <br/>
          <Row>
            <Col md={4}>
              <div className="card">
              <h3 align="center">Proveedores</h3>
              <Table size="sm" striped borderless className="table-responsive-xl">
                  <tr>
                    <th className="tituloTabla">Proveedor</th>
                    <th className="tituloTabla">Cantidad</th>
                    </tr>
              <tbody>
                    {graficaProveedor
                    .map((a)=>{
                      totalProveedor = totalProveedor + a.cantidad
                      return(
                        <tr>
                        <td>{a.proveedor}</td>
                        <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                        </tr>
                    )})}
                     <tr>
                      <td className="negrita">Total</td>
                      <td className="negrita">{new Intl.NumberFormat("en-US").format(totalProveedor)}</td>
                    </tr>
                    </tbody>
              </Table>
              </div>
            </Col>
            <Col md={4}>
              <div className="card">
              <h3 align="center">Articulos</h3>
              <Table size="sm" striped borderless className="table-responsive-xl">
                  <tr>
                    <th className="tituloTabla">Articulo</th>
                    <th className="tituloTabla">Cantidad</th>
                    </tr>
              <tbody>
                    {graficaArticulo
                    .map((a)=>{
                      totalArticulo = totalArticulo + a.cantidad
                      return(
                        <tr>
                        <td>{a.articulo}</td>
                        <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                        </tr>
                    )})}
                      <tr>
                      <td className="negrita">Total</td>
                      <td className="negrita">{new Intl.NumberFormat("en-US").format(totalArticulo)}</td>
                    </tr>
                    </tbody>
              </Table>
              </div>
            </Col>
            <Col md={4}>
              <div className="card">
              <h3 align="center">Colores</h3>
              <Table size="sm" striped borderless className="table-responsive-xl">
                  <tr>
                    <th className="tituloTabla">Color</th>
                    <th className="tituloTabla">Cantidad</th>
                    </tr>
              <tbody>
                    {graficaColor
                    .map((a)=>{
                      totalColor = totalColor + a.cantidad
                      return(
                        <tr>
                        <td>{a.color}</td>
                        <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                        </tr>
                    )})}
                    <tr>
                      <td className="negrita">Total</td>
                      <td className="negrita">{new Intl.NumberFormat("en-US").format(totalColor)}</td>
                    </tr>
                    </tbody>
              </Table>
              </div>
            </Col>
            </Row>
          </div>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Resumen de Compras</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default TablaCompras;

import React, { useEffect, useState, useMemo } from "react";
import Pagination from "react-bootstrap/Pagination";
import {Button, Row, ButtonGroup, Col} from "reactstrap";

const PaginationComponent = ({
    total = 0,
    itemsPerPage = 10,
    currentPage = 1,
    onPageChange
}) => {
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        if (total > 0 && itemsPerPage > 0)
            setTotalPages(Math.ceil(total / itemsPerPage));
    }, [total, itemsPerPage]);

    const paginationItems = useMemo(() => {
        const pages = [];

        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => onPageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        return pages;
    }, [totalPages, currentPage]);

    if (totalPages === 0) return null;

    return (
        <>
        <div>
            <Row>
        <Col sm={8}>
            <ButtonGroup>
              {currentPage == 1 ? (
                  <Button
                  size="sm"
                  className="btn"
                //   color="#0F3A5C"
                  color="danger"
                  disabled
                  onClick={() => onPageChange(currentPage - 1)}
                >
                  {/* PREV */}
                  <i class="fas fa-arrow-left"></i>
                </Button>
              ) :(
                <Button
                  size="sm"
                  className="btn"
                //   color="#0F3A5C"
                  color="danger"
                  onClick={() => onPageChange(currentPage - 1)}
                >
                  {/* PREV */}
                  <i class="fas fa-arrow-left"></i>
                </Button>
              ) }

                {currentPage == totalPages ? (
                  <Button
                  size="sm"
                  className="btn"
                //   color="#0F3A5C"
                  color="info"
                  disabled
                  onClick={() => onPageChange(currentPage + 1)}
                >
                  {/* SIG */}
                  <i class="fas fa-arrow-right"></i>
                </Button>
              ) :(
                <Button
                  size="sm"
                  className="btn"
                //   color="#0F3A5C"
                  color="info"
                  onClick={() => onPageChange(currentPage + 1)}
                >
                  {/* SIG */}
                  <i class="fas fa-arrow-right"></i>
                </Button>
              ) }
              </ButtonGroup>
              <h5>Pagina {currentPage} de {totalPages}</h5>
              </Col>
              </Row>
        </div>
        </>
    );
};
        {/* <Pagination size="sm">
            <Pagination.Prev
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
            />
            {paginationItems}
            <Pagination.Next
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            />
        </Pagination> */}
export default PaginationComponent;
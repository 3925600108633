import React, { useState, useEffect, useContext, useRef, useLayoutEffect, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
} from "reactstrap";
import moment from 'moment';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";



function GraficaVentasDesgloce() {
  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const { user } = useContext(AuthContext);
  const URL_GRAFICAS = process.env.REACT_APP_URL_GRAFICAS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;

  const [graficas, setGraficas] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [value, setValue] = useState("");
  const [vendedorUnicos, setVendedorUnicos] = useState([]);
  const [clienteUnicos, setClienteUnicos] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [cargando, setCargando] = useState(true);

  const Toast = Swal.mixin({
    toast: true,
    position: 'center',
    showConfirmButton: false,
    // timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  useMemo(()=>{

    axios
    .get(`${URL_GRAFICAS}/ventaDesgloce/${selectedFechaInicio}/${selectedFechaFin}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allGraficas = response.data
      // console.log(allGraficas)
        let arrayTabla = allGraficas
        .map((a)=>{
          if(
            (selectedColaborador == 0 || a.Vendedor == selectedColaborador) &&
            (selectedCliente == 0 || a.Cliente == selectedCliente)
            ){
            return{
              Importe: a.Importe,
              Mes: a.Mes,
              idVendedor: a.idVendedor,
              Vendedor: a.Vendedor,
              idCliente: a.idCliente,
              Cliente: a.Cliente,
            }
          }
        })
          .filter(function (el) {
            return el != null;
          });

          let dataFinal = Object.values(arrayTabla);


      let agrupadoCompras2 = dataFinal.reduce(function (groups, item) {
        const val = item["Mes"];
        groups[val] = groups[val] || {
          Mes: item.Mes,
        };
        // groups[val].Importe += item.Importe;
        groups[val].Mes = item.Mes;
        groups[val][item.Cliente] = item.Importe
        return groups;
      },[])

      let data2 = Object.values(agrupadoCompras2)
      // .sort((a, b) => (a.Mes > b.Mes ? 1 : -1))

      setGraficas(data2);



      let unicosV = allGraficas.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.Vendedor === value.Vendedor)
      )
      .sort((a, b) => (a.Vendedor > b.Vendedor ? 1 : -1))
      setVendedorUnicos(unicosV);

      let unicosC = allGraficas.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.Cliente === value.Cliente)
      )
      .sort((a, b) => (a.Cliente > b.Cliente ? 1 : -1))
      setClienteUnicos(unicosC);

      setCargando(false)
      Toast.close()

    })
    .catch((err) => {
      console.log(err);
    });

    if(cargando){
      Toast.fire({
        icon: 'success',
        title: 'Danos unos segundos....'
      })
    }


  },[selectedFechaInicio, selectedFechaFin, cargando, selectedCliente, selectedColaborador])

      const options = clientes.map((option) => {
      const junta = option.nombre_comercial + " " + option.codigo;
      const firstLetter = option.nombre_comercial[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...option,
        junta,
      };
    });


  useLayoutEffect(() => {
    
    let root = am5.Root.new("chartdiv");

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      layout: root.verticalLayout
    }));

    let data = graficas


// Create axes
// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
  categoryField: "Mes",
  renderer: am5xy.AxisRendererX.new(root, {}),
  tooltip: am5.Tooltip.new(root, {})
}));

xAxis.data.setAll(data);

let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
  min: 0,
  renderer: am5xy.AxisRendererY.new(root, {})
}));

let legend = chart.children.push(am5.Legend.new(root, {
  centerX: am5.p50,
  x: am5.p50
}));

  // Add series
// https://www.amcharts.com/docs/v5/charts/xy-chart/series/
function makeSeries(name, fieldName) {
  let series = chart.series.push(am5xy.ColumnSeries.new(root, {
    name: name,
    stacked: true,
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: fieldName,
    categoryXField: "Mes"
  }));

  series.columns.template.setAll({
    tooltipText: "{name}, {categoryX}: {valueY}",
    tooltipY: am5.percent(10)
  });
  series.data.setAll(data);

  // Make stuff animate on load
  // https://www.amcharts.com/docs/v5/concepts/animations/
  series.appear();

  series.bullets.push(function () {
    return am5.Bullet.new(root, {
      // sprite: am5.Label.new(root, {
      //   text: "{valueY}",
      //   fill: root.interfaceColors.get("alternativeText"),
      //   centerY: am5.p50,
      //   centerX: am5.p50,
      //   populateText: true
      // })
    });
  });

  // legend.data.push(series);
}

clienteUnicos.map((a)=>{
  makeSeries(a.Cliente, a.Cliente);
})


// Make stuff animate on load
// https://www.amcharts.com/docs/v5/concepts/animations/
chart.appear(1000, 100);

    // Add cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {})
    });



    return () => {
      root.dispose();
    };
  }, [graficas]);

  return (
    <>
    <Header />
    <br />
    <br />
    {/* {user.menu_reportes ? ( */}
      <div className="card container col-12">
        <h3 align="center">Desgloce de Venta</h3>
    <div className="container col-12">
    <Row>

          <Col md={2}>
            <Label className="mr-sm-2">Fecha Inicio</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaInicio}
              required
              onChange={(e) => {
                setSelectedFechaInicio(e.target.value)
                setCargando(true)
              }}
            />
          </Col>
          <Col md={2}>
            <Label className="mr-sm-2">Fecha Fin</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaFin}
              required
              onChange={(e) => {
                setSelectedFechaFin(e.target.value)
                setCargando(true)
              }}
            />
          </Col>

      

          <Col md={2}>
                    <Label>Vendedor</Label>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedColaborador}
                      onChange={(e) => {
                        setSelectedColaborador(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {vendedorUnicos
                        .map((a) => {
                          return (
                            <option value={a.Vendedor}>{a.Vendedor}</option>
                          );
                        })}
                    </Input>
                  </Col>

                  <Col md={2}>
                    <Label>Cliente</Label>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedCliente}
                      onChange={(e) => {
                        setSelectedCliente(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {clienteUnicos
                        .map((a) => {
                          return (
                            <option value={a.Cliente}>{a.Cliente}</option>
                          );
                        })}
                    </Input>
                  </Col>

        </Row>
        </div>
        <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
            </div>
    {/*  ) : undefined} */}
  </>
  );
}
export default GraficaVentasDesgloce;
